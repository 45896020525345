import { RangeSlider } from '@daangn/sprout-components-slider';

import { CarYearMap } from './constants';
import Section from './Section';
import { getLabeledPoints } from './utils';

type Props = {
  carYearRangeMax: number;
  carYearRangeMin: number;
  handleChangeCarYear: (value: [number, number]) => void;
  modelYearMax: null | number;
  modelYearMin: null | number;
};

const carYearMarkers = Object.entries(getLabeledPoints(CarYearMap)).map(([k, v], index, list) => {
  let label = '';

  if (v) {
    label = `${v}년식`;
  } else {
    label = '전체';
  }

  return {
    value: Number(k),
    label,
    align:
      index === 0 ? ('start' as const) : index === list.length - 1 ? ('end' as const) : undefined,
  };
});

const CarYearSection: React.FC<Props> = ({
  modelYearMin,
  modelYearMax,
  carYearRangeMin,
  carYearRangeMax,
  handleChangeCarYear,
}) => {
  return (
    <Section.Container>
      <Section.Header>
        <Section.Title>연식</Section.Title>
        <Section.Description>
          {modelYearMin === null && modelYearMax === null ? (
            '전체'
          ) : (
            <>
              {modelYearMin ? `${modelYearMin}년식` : '전체'}
              {' - '}
              {modelYearMax ? `${modelYearMax}년식` : '전체'}
            </>
          )}
        </Section.Description>
      </Section.Header>
      <Section.SliderWrapper>
        <RangeSlider
          markers={carYearMarkers}
          maxValue={carYearRangeMax}
          minStepsBetweenThumbs={1}
          minValue={carYearRangeMin}
          onChange={handleChangeCarYear}
          value={[modelYearMin ?? carYearRangeMin, modelYearMax ?? carYearRangeMax]}
        />
      </Section.SliderWrapper>
    </Section.Container>
  );
};

export default CarYearSection;
