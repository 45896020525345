import { RangeSlider } from '@daangn/sprout-components-slider';

import { getInvertedMap } from '@/utils/misc';
import { getBillionFormatFromPrice } from '@/utils/number';

import { PriceMap } from './constants';
import Section from './Section';
import { getLabeledPoints } from './utils';

type Props = {
  handleChangePrice: (value: [number, number]) => void;
  priceMax: null | number;
  priceMin: null | number;
  priceRangeMax: number;
  priceRangeMin: number;
};

const InvertedPriceMap = getInvertedMap(PriceMap);
const priceMarkers = Object.entries(getLabeledPoints(PriceMap)).map(([k, v], index, list) => {
  let label = '';

  if (v || Number(k) === 0) {
    label = `${getBillionFormatFromPrice(v ?? 0)}원`;
  } else {
    label = '전체';
  }

  return {
    value: Number(k),
    label,
    align:
      index === 0
        ? ('start' as const)
        : index === list.length - 1
          ? ('end' as const)
          : ('center' as const),
  };
});

const PriceSection: React.FC<Props> = ({
  priceMin,
  priceMax,
  priceRangeMin,
  priceRangeMax,
  handleChangePrice,
}) => {
  return (
    <Section.Container>
      <Section.Header>
        <Section.Title>가격</Section.Title>
        <Section.Description>
          {priceMin === null && priceMax === null ? (
            '전체'
          ) : (
            <>
              {getBillionFormatFromPrice(priceMin ?? 0)}원{' - '}
              {priceMax !== null ? `${getBillionFormatFromPrice(priceMax)}원` : '전체'}
            </>
          )}
        </Section.Description>
      </Section.Header>
      <Section.SliderWrapper>
        <RangeSlider
          markers={priceMarkers}
          maxValue={priceRangeMax}
          minStepsBetweenThumbs={1}
          minValue={priceRangeMin}
          onChange={handleChangePrice}
          step={1}
          value={[
            priceMin !== null ? InvertedPriceMap[priceMin] : priceRangeMin,
            priceMax !== null ? InvertedPriceMap[priceMax] : priceRangeMax,
          ]}
        />
      </Section.SliderWrapper>
    </Section.Container>
  );
};

export default PriceSection;
