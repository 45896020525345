import {
  AlertDialog,
  DialogContainer,
  SeedAlertDialogProps,
} from '@daangn/sprout-components-dialog';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { Merge } from '@/types/global';

import { useOverlayState } from './useOverlayState';

type Props = Merge<
  Pick<
    SeedAlertDialogProps,
    | 'description'
    | 'maxWidth'
    | 'primaryActionLabel'
    | 'secondaryActionIntent'
    | 'secondaryActionLabel'
    | 'title'
  >,
  {
    id: string;
  }
>;

type Answer = 'exit' | 'primary' | 'secondary';

export const useAlertDialog = ({
  id,
  title,
  description,
  primaryActionLabel,
  secondaryActionLabel,
  secondaryActionIntent,
  maxWidth = 272,
}: Props) => {
  const [val, setTrue, setFalse] = useOverlayState(id);
  const resolveRef = useRef<((value: Answer | PromiseLike<Answer>) => void) | undefined>(undefined);

  const setAnswer = useCallback((answer: Answer) => {
    resolveRef.current?.(answer);
    resolveRef.current = undefined;
  }, []);

  const openDialog = useCallback(() => {
    setTrue();
    return new Promise<Answer>((resolve) => {
      resolveRef.current = resolve;
    });
  }, [setTrue]);

  const handleClickPrimary = useCallback(() => {
    setAnswer('primary');
    setFalse();
  }, [setAnswer, setFalse]);

  const handleClickSecondary = useCallback(() => {
    setAnswer('secondary');
    setFalse();
  }, [setAnswer, setFalse]);

  const handleClickExit = useCallback(() => {
    setAnswer('exit');
    setFalse();
  }, [setAnswer, setFalse]);

  useEffect(() => {
    if (val === false) {
      setAnswer('exit');
    }
  }, [setAnswer, val]);

  const dialogNode = useMemo(
    () =>
      val ? (
        <DialogContainer onEsc={handleClickExit}>
          <AlertDialog
            description={description}
            maxWidth={maxWidth}
            onPrimaryAction={handleClickPrimary}
            onSecondaryAction={handleClickSecondary}
            primaryActionLabel={primaryActionLabel}
            secondaryActionIntent={secondaryActionIntent}
            secondaryActionLabel={secondaryActionLabel}
            title={title}
          />
        </DialogContainer>
      ) : null,
    [
      description,
      handleClickExit,
      handleClickPrimary,
      handleClickSecondary,
      maxWidth,
      primaryActionLabel,
      secondaryActionIntent,
      secondaryActionLabel,
      title,
      val,
    ]
  );
  return [openDialog, dialogNode] as const;
};
