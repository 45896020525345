import CONFIG, { IS_PROD } from '@/constants/config';
import { getUrlWithQuery } from '@/utils/url';

type WithQuery<T = unknown> = { queries?: Record<string, string> } & T;

function createScheme(path: string, queries?: Record<string, string>) {
  const url = getUrlWithQuery(`${CONFIG.APP_SCHEME}://${path}`, queries);

  return url;
}

const Scheme = {
  User: (id: string) => createScheme(`users/${id}`),
  MyWatches: createScheme('my_watches'),
  Checkin: createScheme('checkin'),
};

function createBizProfileScheme(path: string, queries?: Record<string, string>) {
  const url = getUrlWithQuery(`${CONFIG.BIZ_PROFILE_HOST}/${path}`, queries);

  return {
    url,
    stage: process.env.WEBVIEW_STAGE,
  };
}

export const BizProfileScheme = {
  // 비즈프로필 생성
  Create: ({ queries }: WithQuery) => createBizProfileScheme('onboarding', queries),
  // 비즈프로필 소식 작성
  Write: ({ bizId, queries }: WithQuery<{ bizId: string }>) =>
    createBizProfileScheme(`biz_accounts/${bizId}/manager/posts/new`, queries),
};

export const CsCenterScheme = {
  Home: createScheme('web/wv/feedbacks/new', { category_id: IS_PROD ? '562' : '232' }),
  CarIsOwnedBySomeoneElse: createScheme('web/wv/feedbacks/new', {
    category_id: IS_PROD ? '2060' : '233',
  }),
  OwnerNameMissMatched: createScheme('web/wv/feedbacks/new', {
    category_id: IS_PROD ? '2027' : '234',
  }),
  VerificationCodeNotSended: createScheme('web/wv/faqs/157'),
};

export const INSPECTOR_TRAINEE_APPLY_LINK = 'https://car-inspector.oopy.io/';

export default Scheme;
