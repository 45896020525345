import styled from '@emotion/styled';
import { getYear } from 'date-fns';
import { isEqual } from 'lodash-es';
import { rem } from 'polished';
import { useCallback, useEffect, useState } from 'react';

import BottomFixedLayer from '@/components/Base/BottomFixedLayer';
import FilterButtonFooter from '@/components/Filter/FilterButtonFooter';
import useTrack from '@/hooks/useTrack';
import useUpdateFilter from '@/hooks/useUpdateFilter';
import { safeAreaInset } from '@/styles/mixins';
import { CarType } from '@/types/CarData/CarType';
import { CompanyOrigin } from '@/types/CarData/CompanyOrigin';
import { FuelType, OneToNFuelTypes } from '@/types/CarData/FuelType';
import { GearType, OneToNGearTypes } from '@/types/CarData/GearType';
import { SaleType } from '@/types/CarData/SaleType';
import { YEAR_RANGE_SIZE } from '@/utils/date';

import CarTypeSection from './CarTypeSection';
import CarYearSection from './CarYearSection';
import CompanyOriginSection from './CompanyOriginSection';
import { CarYearMap, DriveDistanceMap, PriceMap, priceKeys } from './constants';
import DriveDistanceSection from './DriveDistanceSection';
import {
  FilterState,
  defaultFilterState,
  useFilterContext,
  useFilterUpdaterContext,
} from './FilterContextProvider';
import FuelTypeSection from './FuelTypeSection';
import GearSection from './GearSection';
import PriceSection from './PriceSection';
import SaleTypeSection from './SaleTypeSection';
import { getKeys } from './utils';

const thisYear = getYear(new Date());

const getCarYearFromSliderValue = (value: number) => {
  if (value < thisYear - YEAR_RANGE_SIZE) {
    return null;
  }
  if (value > thisYear) {
    return null;
  }
  return value;
};
const carYearKeys = getKeys(CarYearMap);
const driveDistanceKeys = getKeys(DriveDistanceMap);

const toggleInArray = <T,>(arrayOrNull: T[] | null, item: T | T[]) => {
  const array = arrayOrNull ?? [];
  const items = Array.isArray(item) ? item : [item];
  if (array.some((i) => items.includes(i))) {
    const nextArray = array.filter((i) => !items.includes(i));
    return nextArray.length > 0 ? nextArray : [];
  }
  return [...array, ...items];
};

const OptionsFilter = () => {
  const { filter: prevFilter, initialFilter, allowedFilterFields, isPending } = useFilterContext();
  const { updateOpenedFilter, startTransition } = useFilterUpdaterContext();
  const setStoreOptions = useUpdateFilter();
  const { trackWithActivityName } = useTrack();
  const [options, setOptions] = useState<FilterState>(prevFilter);

  const showCarType = allowedFilterFields.includes('carTypes');
  const showCompanyOrigin = allowedFilterFields.includes('companyOrigin');

  const updateOptions = <T extends Exclude<keyof FilterState, 'distance'>>(
    key: T,
    value: FilterState[T]
  ) => {
    setOptions((prev) => ({ ...prev, [key]: value }));
  };

  const toggleCarType = useCallback(
    (carType: CarType) => {
      updateOptions('carTypes', toggleInArray(options.carTypes, carType));
    },
    [options.carTypes]
  );

  const toggleFuelType = useCallback(
    (fuelType: FuelType) => {
      updateOptions(
        'fuelTypes',
        toggleInArray(options.fuelTypes, OneToNFuelTypes[fuelType] ?? fuelType)
      );
      setOptions((prev) => {
        const fuelTypesWithoutUnknown = (prev.fuelTypes ?? []).filter(
          (fuel) => fuel !== FuelType.Unknown
        );

        if (fuelTypesWithoutUnknown.length > 0) {
          return {
            ...prev,
            fuelTypes: [...new Set([...(prev.fuelTypes ?? []), FuelType.Unknown])],
          };
        }
        return {
          ...prev,
          fuelTypes: fuelTypesWithoutUnknown.length > 0 ? fuelTypesWithoutUnknown : null,
        };
      });
    },
    [options.fuelTypes]
  );

  const toggleGear = useCallback(
    (gearType: GearType) => {
      updateOptions('gears', toggleInArray(options.gears, OneToNGearTypes[gearType] ?? gearType));
      setOptions((prev) => {
        const gearsWithoutUnknown = (prev.gears ?? []).filter((gear) => gear !== GearType.Unknown);

        if (gearsWithoutUnknown.length > 0) {
          return { ...prev, gears: [...new Set([...(prev.gears ?? []), GearType.Unknown])] };
        }
        return { ...prev, gears: gearsWithoutUnknown };
      });
    },
    [options.gears]
  );

  const handleClickRefresh = useCallback(() => {
    trackWithActivityName('click_reset_filter_options');
    setOptions({ ...defaultFilterState, ...initialFilter, distance: prevFilter.distance });
  }, [initialFilter, prevFilter.distance, trackWithActivityName]);

  const handleClickSave = useCallback(() => {
    updateOpenedFilter(null);
    if (!isEqual(prevFilter, options)) {
      startTransition(() => {
        setStoreOptions(options);
      });
      trackWithActivityName('click_change_filter_options', { ...options });
    }
  }, [
    options,
    prevFilter,
    setStoreOptions,
    startTransition,
    trackWithActivityName,
    updateOpenedFilter,
  ]);

  const handleChangePrice = useCallback((value: [number, number]) => {
    const [valueMin, valueMax] = value;

    updateOptions('priceMin', PriceMap[valueMin]);
    updateOptions('priceMax', PriceMap[valueMax]);
  }, []);

  const handleChangeCarYear = useCallback((value: [number, number]) => {
    const [valueMin, valueMax] = value;

    updateOptions('modelYearMin', getCarYearFromSliderValue(valueMin));
    updateOptions('modelYearMax', getCarYearFromSliderValue(valueMax));
  }, []);

  const handleChangeDriveDistance = useCallback((value: [number, number]) => {
    const [valueMin, valueMax] = value;

    updateOptions('driveDistanceMin', DriveDistanceMap[valueMin]);
    updateOptions('driveDistanceMax', DriveDistanceMap[valueMax]);
  }, []);

  const handleChangeCompanyOrigin = useCallback(
    (companyOrigin: CompanyOrigin) => {
      const isSelected = options.companyOrigin === companyOrigin;
      updateOptions('companyOrigin', isSelected ? null : companyOrigin);
    },
    [options.companyOrigin]
  );

  const handleChangeSaleType = useCallback(
    (saleType: SaleType) => {
      updateOptions('saleTypes', toggleInArray(options.saleTypes, saleType));
    },
    [options.saleTypes]
  );

  const priceRangeMin = Math.min(...priceKeys);
  const priceRangeMax = Math.max(...priceKeys);

  const carYearRangeMin = Math.min(...carYearKeys);
  const carYearRangeMax = Math.max(...carYearKeys);

  const driveDistanceRangeMin = Math.min(...driveDistanceKeys);
  const driveDistanceRangeMax = Math.max(...driveDistanceKeys);

  useEffect(() => {
    setOptions(prevFilter);
  }, [prevFilter]);

  return (
    <>
      <Container>
        {showCompanyOrigin && (
          <CompanyOriginSection
            companyOrigin={options.companyOrigin}
            handleChangeCompanyOrigin={handleChangeCompanyOrigin}
          />
        )}
        {showCarType && (
          <CarTypeSection
            carTypes={options.carTypes}
            fixedCarTypes={initialFilter?.carTypes ?? undefined}
            handleChangeCarType={toggleCarType}
          />
        )}
        <FuelTypeSection
          fixedFuelTypes={initialFilter?.fuelTypes ?? undefined}
          fuelTypes={options.fuelTypes}
          handleChangeFuelType={toggleFuelType}
        />
        <PriceSection
          handleChangePrice={handleChangePrice}
          priceMax={options.priceMax}
          priceMin={options.priceMin}
          priceRangeMax={priceRangeMax}
          priceRangeMin={priceRangeMin}
        />
        <CarYearSection
          carYearRangeMax={carYearRangeMax}
          carYearRangeMin={carYearRangeMin}
          handleChangeCarYear={handleChangeCarYear}
          modelYearMax={options.modelYearMax}
          modelYearMin={options.modelYearMin}
        />
        <DriveDistanceSection
          driveDistanceMax={options.driveDistanceMax}
          driveDistanceMin={options.driveDistanceMin}
          driveDistanceRangeMax={driveDistanceRangeMax}
          driveDistanceRangeMin={driveDistanceRangeMin}
          handleChangeDriveDistance={handleChangeDriveDistance}
        />
        <GearSection gears={options.gears} handleChangeGear={toggleGear} />

        <SaleTypeSection
          fixedSaleTypes={initialFilter?.saleTypes ?? undefined}
          handleChangeSaleType={handleChangeSaleType}
          saleTypes={options.saleTypes}
        />
      </Container>
      <BottomLayer>
        <FilterButtonFooter
          isLoading={isPending}
          onClickConfirm={handleClickSave}
          onClickRefresh={handleClickRefresh}
        />
      </BottomLayer>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 0 ${rem(16)} ${rem(72 + 16)};
`;

const BottomLayer = styled(BottomFixedLayer)`
  padding: 0;
  ${safeAreaInset({ paddingBottom: 0 })}
`;

export default OptionsFilter;
