import { css } from '@emotion/react';
import { vars } from '@seed-design/design-token';
import React, { Fragment } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';

import { FilterLayerQuery } from '@/__generated__/FilterLayerQuery.graphql';
import BottomSheet from '@/components/Base/BottomSheet/BottomSheet';
import DistanceFilter from '@/components/Filter/DistanceFilter';
import OptionsFilter from '@/components/Filter/OptionsFilter';
import StatusFilter from '@/components/Filter/StatusFilter';
import useTrack from '@/hooks/useTrack';
import { CompanyOrigin } from '@/types/CarData/CompanyOrigin';

import CompanyIdsFilter from './CompanyIdsFilter';
import { useFilterContext, useFilterUpdaterContext } from './FilterContextProvider';
import SortFilter from './SortFilter';

type Props = {
  defaultCompanyOrigin?: CompanyOrigin;
};
const FilterLayer = ({ defaultCompanyOrigin }: Props) => {
  const { trackWithActivityName } = useTrack();
  const query = useLazyLoadQuery<FilterLayerQuery>(
    graphql`
      query FilterLayerQuery {
        ...CompanyIdsFilter_autoupCompanies
      }
    `,
    {}
  );
  const { openedFilter, isPending } = useFilterContext();
  const { updateOpenedFilter } = useFilterUpdaterContext();

  const handleCloseFilter = () => {
    updateOpenedFilter(null);
    trackWithActivityName('click_close_filter', { filter: openedFilter });
  };

  return (
    <>
      <BottomSheet
        active={openedFilter === 'distance'}
        onClickClose={handleCloseFilter}
        title={'동네 범위'}
      >
        <DistanceFilter />
      </BottomSheet>
      <BottomSheet
        active={openedFilter === 'options'}
        onClickClose={handleCloseFilter}
        showCloseButton={true}
        title="필터"
      >
        <OptionsFilter />
      </BottomSheet>
      <BottomSheet
        active={openedFilter === 'sort'}
        loading={isPending}
        onClickClose={handleCloseFilter}
        showCloseButton={true}
        title="정렬"
      >
        <SortFilter />
      </BottomSheet>
      <BottomSheet
        active={openedFilter === 'status'}
        loading={isPending}
        onClickClose={handleCloseFilter}
        title="매물 종류를 선택해 주세요"
      >
        <StatusFilter />
      </BottomSheet>
      <BottomSheet
        active={openedFilter === 'companyIds'}
        onClickClose={handleCloseFilter}
        showCloseButton
        title={
          <Fragment>
            제조사
            <span
              css={css`
                color: ${vars.$semantic.color.primary};
              `}
              id="manufacturer-count"
            />
          </Fragment>
        }
      >
        <CompanyIdsFilter defaultCompanyOrigin={defaultCompanyOrigin} queryRef={query} />
      </BottomSheet>
    </>
  );
};

export default FilterLayer;
