import { IconExpandMoreRegular } from '@seed-design/icon';
import { useActivity } from '@stackflow/react';
import React, { memo } from 'react';
import { graphql, useFragment } from 'react-relay';

import { ArticleStatusChangeSection_article$key } from '@/__generated__/ArticleStatusChangeSection_article.graphql';
import RenewedBottomSheet from '@/components/Base/BottomSheet/RenewedBottomSheet';
import RenewedBottomSheetItem from '@/components/Base/BottomSheet/RenewedBottomSheetItem';
import { getSeedColor } from '@/components/Base/Box';
import HStack from '@/components/Base/HStack';
import { Label3Bold } from '@/components/Base/Text';
import { useAlertDialog } from '@/hooks/useAlertDialog';
import { useEnterDoneEffectOnce } from '@/hooks/useEnterDoneEffectOnce';
import useHighlight from '@/hooks/useHighlight';
import useMyTradeReviews from '@/hooks/useMyTradeReviews';
import { useOverlayState } from '@/hooks/useOverlayState';
import useTrack from '@/hooks/useTrack';
import useUpdateArticleStatus from '@/hooks/useUpdateArticleStatus';
import useUser from '@/hooks/useUser';
import { useFlow } from '@/stackflow';
import { ArticleStatus, ArticleStatusText } from '@/types/Article/ArticleStatus';
import { sessionStorage } from '@/utils/storage';
import { getSizeProps } from '@/utils/style';

import { useArticleContext } from '../ArticleContext';

type Props = {
  articleRef: ArticleStatusChangeSection_article$key;
};

const ArticleStatusChangeSection: React.FC<Props> = ({ articleRef }) => {
  const { isMyArticle } = useArticleContext();
  const { isRoot } = useActivity();
  const { push } = useFlow();
  const { track } = useTrack();
  const { checkLogin } = useUser();
  const { ref: highlightRef, on } = useHighlight<HTMLDivElement>();
  const { updateArticleStatus } = useUpdateArticleStatus();
  const myTradeReviews = useMyTradeReviews();
  const [statusBoxOpened, openStatusBox, closeStatusBox] = useOverlayState('statusChangeMenu');
  const [askChangeStatusIfTradeReviewIsExist, askChangeStatusIfTradeReviewIsExistDialog] =
    useAlertDialog({
      id: 'askChangeStatusIfTradeReviewExist',
      description: '판매중으로 변경하면 서로 주고받은 거래후기가 취소돼요. 그래도 변경하시겠어요?',
      primaryActionLabel: '변경하기',
      secondaryActionLabel: '취소',
    });
  const [askChangeStatusWhileAd, askChangeStatusWhileAdDialog] = useAlertDialog({
    id: 'askChangeStatusWhileAd',
    description:
      '이 매물은 광고 중이에요. 매물 상태를 판매완료로 변경하면 이 매물의 광고는 더 이상 진행할 수 없고, 금액은 환불되지 않아요. 그래도 변경하시겠어요?',
    primaryActionLabel: '변경하기',
    secondaryActionLabel: '취소',
  });

  const { id, status, promotionEndsAt, chatRooms } = useFragment(
    graphql`
      fragment ArticleStatusChangeSection_article on Article {
        id
        status
        promotionEndsAt
        chatRooms {
          id
        }
      }
    `,
    articleRef
  );
  const tradeReview = myTradeReviews.find((review) => review.articleId === id);
  const handleChangeStatus = async (selectedStatus: ArticleStatus) => {
    if (!checkLogin()) {
      return;
    }
    closeStatusBox();
    if (promotionEndsAt && selectedStatus === ArticleStatus.Traded) {
      const answer = await askChangeStatusWhileAd();
      if (answer !== 'primary') {
        return undefined;
      }
    }
    if (status === 'TRADED' && selectedStatus === ArticleStatus.OnSale && tradeReview) {
      const answer = await askChangeStatusIfTradeReviewIsExist();
      if (answer !== 'primary') {
        return undefined;
      }
    }
    const changedStatus = await updateArticleStatus({ id, status: selectedStatus });
    track('article_click_change_status', { status: selectedStatus });

    if (!chatRooms || !chatRooms.length) {
      return;
    }
    if (changedStatus === 'TRADED') {
      return push('article_select_buyer', { articleId: id });
    }
    if (changedStatus === 'RESERVED') {
      return push('article_select_reserver', { articleId: id });
    }
  };

  useEnterDoneEffectOnce(() => {
    const referrer = sessionStorage.getReferrer();
    if (isRoot && referrer === 'unsynced_article_status') {
      setTimeout(() => {
        on();
      }, 300);
    }
  });

  if (!isMyArticle) {
    return null;
  }

  return (
    <>
      <HStack
        align="center"
        bc="gray300"
        br={5}
        gap={4}
        onClick={openStatusBox}
        px={14}
        py={8}
        ref={highlightRef}
        w="fit-content"
      >
        <Label3Bold>{ArticleStatusText[status]}</Label3Bold>
        {<IconExpandMoreRegular {...getSizeProps(16)} color={getSeedColor('gray900')} />}
      </HStack>
      <RenewedBottomSheet active={statusBoxOpened} onClickClose={closeStatusBox}>
        {Object.values(ArticleStatus)
          .filter((status) => status !== ArticleStatus.None)
          .map((status) => (
            <RenewedBottomSheetItem key={status} onClick={() => handleChangeStatus(status)}>
              {ArticleStatusText[status]}
            </RenewedBottomSheetItem>
          ))}
      </RenewedBottomSheet>
      {askChangeStatusWhileAdDialog}
      {askChangeStatusIfTradeReviewIsExistDialog}
    </>
  );
};

export default memo(ArticleStatusChangeSection);
