import { RefObject, useEffect, useState } from 'react';

import useIntersectionObserver from './useIntersectionObserver';

const DefaultOptions = { root: null, rootMargin: '0px', threshold: 1 };

const useInViewportOnce = (
  ref: RefObject<HTMLElement>,
  options: IntersectionObserverInit = DefaultOptions
) => {
  const [isInViewport, setIsInViewport] = useState(false);
  const isIntersecting = useIntersectionObserver(ref, options);

  useEffect(() => {
    if (isIntersecting && !isInViewport) {
      setIsInViewport(true);
    }
  }, [isInViewport, isIntersecting]);

  return isInViewport;
};

export default useInViewportOnce;
