import { ChipToggleButton } from '@daangn/sprout-components-chips';
import { P, match } from 'ts-pattern';

import { FuelType, FuelTypeText, OneToNFuelTypes } from '@/types/CarData/FuelType';

import Section from './Section';

const visibleFuelTypes: FuelType[] = [
  FuelType.Gasoline,
  FuelType.Diesel,
  FuelType.Lpg,
  FuelType.Cng,
  FuelType.Electronic,
  FuelType.HydrogenElectric,
  FuelType.FuelCellElectronic,
  FuelType.Hybrid,
];

const FuelTypeSection: React.FC<{
  fixedFuelTypes?: FuelType[];
  fuelTypes: FuelType[] | null;
  handleChangeFuelType: (fuelType: FuelType) => void;
}> = ({ fuelTypes, handleChangeFuelType, fixedFuelTypes }) => {
  return (
    <Section.Container>
      <Section.Header>
        <Section.Title>연료</Section.Title>
      </Section.Header>
      <Section.ButtonWrapper>
        {visibleFuelTypes.map((fuelType: FuelType) => {
          const isActive = match(OneToNFuelTypes[fuelType])
            .with(P.nullish, () => fuelTypes?.includes(fuelType) ?? false)
            .with(P._, (nFuelTypes) => nFuelTypes.some((v) => fuelTypes?.includes(v) ?? false))
            .exhaustive();
          const isDisabled = match(OneToNFuelTypes[fuelType])
            .with(P.nullish, () => fixedFuelTypes?.includes(fuelType) ?? false)
            .with(P._, (nFuelTypes) => nFuelTypes.some((v) => fixedFuelTypes?.includes(v) ?? false))
            .exhaustive();
          return (
            <ChipToggleButton
              isDisabled={isDisabled}
              isSelected={isActive}
              key={fuelType}
              onClick={() => handleChangeFuelType(fuelType)}
              size="small"
            >
              {FuelTypeText[fuelType]}
            </ChipToggleButton>
          );
        })}
      </Section.ButtonWrapper>
    </Section.Container>
  );
};

export default FuelTypeSection;
