import { ChipToggleButton } from '@daangn/sprout-components-chips';

import { CompanyOrigin, CompanyOriginText } from '@/types/CarData/CompanyOrigin';

import Section from './Section';

type Props = {
  companyOrigin: CompanyOrigin | null;
  handleChangeCompanyOrigin: (companyOrigin: CompanyOrigin) => void;
};

const CompanyOriginSection: React.FC<Props> = ({ companyOrigin, handleChangeCompanyOrigin }) => {
  return (
    <Section.Container>
      <Section.Header>
        <Section.Title>국산차/수입차</Section.Title>
      </Section.Header>
      <Section.ButtonWrapper>
        {Object.values(CompanyOrigin).map((origin) => {
          const isActive = companyOrigin === origin;

          return (
            <ChipToggleButton
              isSelected={isActive}
              key={origin}
              onClick={() => handleChangeCompanyOrigin(origin)}
              size="small"
            >
              {CompanyOriginText[origin]}
            </ChipToggleButton>
          );
        })}
      </Section.ButtonWrapper>
    </Section.Container>
  );
};

export default CompanyOriginSection;
