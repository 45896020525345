import { Spinner } from '@daangn/sprout-components-spinner';
import styled from '@emotion/styled';
import { rem } from 'polished';
import React, { useEffect, useRef } from 'react';

import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import useLatest from '@/hooks/useLatest';

interface Props {
  onLoad?: () => void;
}

const LoadingBox: React.FC<React.PropsWithChildren<Props>> = ({ onLoad, ...props }) => {
  const $container = useRef(null);
  const isIntersecting = useIntersectionObserver($container);
  const onLoadLatest = useLatest(onLoad);

  // FIXME: useLatest 없이 동작해야함.
  useEffect(() => {
    isIntersecting && onLoadLatest.current?.();
  }, [isIntersecting, onLoadLatest]);

  return (
    <Container ref={$container} {...props}>
      <Spinner />
    </Container>
  );
};

export default LoadingBox;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${rem(32)};
  width: 100%;
`;
