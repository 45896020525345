import { EmblaCarouselType } from 'embla-carousel';
import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';
import React, { Children, PropsWithChildren, useEffect, useState } from 'react';

import Box from '@/components/Base/Box';
import Circle from '@/components/Base/Circle';
import HStack from '@/components/Base/HStack';
import VStack from '@/components/Base/VStack';
import { PropsOf } from '@/types/React/PropsOf';

type Props = PropsWithChildren<
  {
    // 슬라이드 롤링 간격
    delay?: number;
  } & PropsOf<typeof VStack>
>;

const RollingCarousel: React.FC<Props> = ({ children, delay = 3000, ...props }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true }, [Autoplay({ delay })]);

  const handleClickDot = (index: number) => {
    if (!emblaApi) {
      return;
    }
    emblaApi.scrollTo(index);
  };

  useEffect(() => {
    if (!emblaApi) {
      return;
    }
    const handleSelect = (emblaApi: EmblaCarouselType) => {
      setSelectedIndex(emblaApi.selectedScrollSnap());
    };
    handleSelect(emblaApi);
    return emblaApi.on('reInit', handleSelect).on('select', handleSelect).clear;
  }, [emblaApi]);

  return (
    <VStack gap={8} ox="hidden" oy="hidden" ref={emblaRef} {...props}>
      <HStack>
        {Children.map(children, (child, index) => (
          <Box f="0 0 100%" key={index} minW={0} mr={16}>
            {child}
          </Box>
        ))}
      </HStack>
      <HStack align="center" gap={8} justify="center">
        {Children.map(children, (_, index) => {
          const isSelected = index === selectedIndex;

          return (
            <Circle
              bg={isSelected ? 'primary' : 'grayAlpha100'}
              onClick={() => handleClickDot(index)}
              size={6}
            />
          );
        })}
      </HStack>
    </VStack>
  );
};

export default RollingCarousel;
