import * as React from 'react';

function withDefaultProps<P extends React.PropsWithChildren<unknown>>(
  Component: React.ComponentType<P>,
  defaultProps: Partial<P>,
  displayName?: string
) {
  const WrappedComponent = (props: Partial<P>) => {
    const mergedProps = {
      ...defaultProps,
      ...props,
    } as P;
    return <Component {...mergedProps} />;
  };
  WrappedComponent.displayName = `withDefaultProps(${displayName ?? Component.displayName ?? 'Unknown'})`;

  return WrappedComponent;
}

export default withDefaultProps;
