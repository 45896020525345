import { ChipToggleButton } from '@daangn/sprout-components-chips';

import { GearType, GearTypeText } from '@/types/CarData/GearType';

import Section from './Section';

const visibleGears: GearType[] = [GearType.Auto, GearType.Manual];

type Props = {
  gears: GearType[] | null;
  handleChangeGear: (gearType: GearType) => void;
};

const GearSection: React.FC<Props> = ({ gears, handleChangeGear }) => {
  return (
    <Section.Container>
      <Section.Header>
        <Section.Title>변속기</Section.Title>
      </Section.Header>
      <Section.ButtonWrapper>
        {visibleGears.map((gearType) => {
          const isActive = (gears ?? []).includes(gearType);

          return (
            <ChipToggleButton
              isSelected={isActive}
              key={gearType}
              onClick={() => handleChangeGear(gearType)}
              size="small"
            >
              {GearTypeText[gearType]}
            </ChipToggleButton>
          );
        })}
      </Section.ButtonWrapper>
    </Section.Container>
  );
};

export default GearSection;
