import * as React from 'react';

export type ImageStatus = State['tag'];

type State =
  | { max: number; retry: number; tag: 'loading' }
  | { retry: number; tag: 'error' }
  | { retry: number; tag: 'loaded' };
type Action = 'Load' | 'ResetRetry' | 'Retry';

/**
 * Generated by ReScript, PLEASE EDIT WITH CARE
 *
 * @see https://rescript-lang.org/try?version=v12.0.0-alpha.1&code=AIFwhg5gFAROEwJQCgQE8AOBTABAZ3BFwF5kccAfHYMPWAGwHswATASwDsFEcAZZ9lygBvAE5YQotAC4cnEABocAWzAAPWfIC+KclRp0YTVlhZI+A0yPGSZcjiB1lK1WrCyjRjUeYCin72sJKU0HJ1RMXDAAYxA2Rg4cUj0LVmcqACVgtHScLLwJLNtkZHoJHHEWAFdojyScKAIwIiUYuISeYgA+ZzwAdzYQaIALBqaWnDb4jh5hXKh+Vk5oMWydJUWWTq7UlitV21kbKScUhYFloNslVTV1vOyeNgAzCuyk4hV1JJ3-L1EriE3rZTi5zkshAcTpM8Dg1IglEUpNtdpdhAA6TFqJTHOy4nAAahwAEZQVRwYJoPDEVgCiAkWgUZs0Zj0djgUCAAxkhqbKwAfQROEFP3whCw8z+gUFShF3TFzQl5C0yCAA
 */
function reducer(state: State, action: Action): State {
  switch (state.tag) {
    case 'loading':
      const retry = state.retry;
      switch (action) {
        case 'Load':
          return {
            tag: 'loaded',
            retry,
          };
        case 'Retry':
          if (retry === state.max) {
            return {
              tag: 'error',
              retry,
            };
          } else {
            return {
              tag: 'loading',
              retry: (retry + 1) | 0,
              max: state.max,
            };
          }
        case 'ResetRetry':
          return {
            tag: 'loading',
            retry: 0,
            max: state.max,
          };
      }
    case 'loaded':
    case 'error':
      return state;
  }
}

function initState(maxRetryCount: number): State {
  return {
    tag: 'loading',
    retry: 0,
    max: maxRetryCount,
  };
}

export function useRetryImage(maxRetryCount = 3) {
  const [state, dispatch] = React.useReducer(reducer, maxRetryCount, initState);

  const onError = () => {
    setTimeout(() => {
      dispatch('Retry');
    }, 1500);
  };

  const onLoad = () => {
    dispatch('Load');
  };

  React.useEffect(() => {
    dispatch('ResetRetry');
  }, [maxRetryCount]);

  return {
    imageKey: state.retry.toString(),
    imageStatus: state.tag,
    imageProps: {
      onError,
      onLoad,
    },
  };
}
