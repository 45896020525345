import * as React from 'react';

import { getLowScaleImageSrc } from '@/utils/image';

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  lowScaleSrc?: string;
  ref?: React.Ref<HTMLImageElement>;
  src?: string;
}

const LowScaleImage = ({ ref: forwardedRef, src, lowScaleSrc, ...props }: Props) => {
  const lowScaleImageSrc = lowScaleSrc ?? getLowScaleImageSrc(src);
  if (!lowScaleImageSrc) {
    return null;
  }

  return <img ref={forwardedRef} src={lowScaleImageSrc} {...props} />;
};

export default LowScaleImage;
