import { TextButton } from '@daangn/sprout-components-button';
import { Divider } from '@daangn/sprout-components-divider';
import { IconCheckFill, IconChevronRightFill, IconConfirmationFill } from '@seed-design/icon';
import React, { useMemo } from 'react';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';

import { ArticleWarrantySection_article$key } from '@/__generated__/ArticleWarrantySection_article.graphql';
import { getSeedColor } from '@/components/Base/Box';
import HStack from '@/components/Base/HStack';
import { BodyM2Regular, Caption2Bold, Label3Bold, Title3Bold } from '@/components/Base/Text';
import VStack from '@/components/Base/VStack';
import WarrantyBadge from '@/components/WarrantyBadge';
import useTrack from '@/hooks/useTrack';
import { useFlow } from '@/stackflow';
import { formatTemplates } from '@/utils/dateStr';
import { getWarrantyDeadline } from '@/utils/inspection/getWarrantyDeadline';
import { getSizeProps } from '@/utils/style';
import { nonNullish } from '@/utils/typeGuard';

type Props = {
  articleRef: ArticleWarrantySection_article$key;
};

const ArticleWarrantySection: React.FC<Props> = ({ articleRef }) => {
  const { id, carData } = useFragment(
    graphql`
      fragment ArticleWarrantySection_article on Article {
        id
        carData {
          inspection {
            ...getWarrantyDeadline_inspection
          }
        }
      }
    `,
    articleRef
  );
  const { push } = useFlow();
  const { track } = useTrack();
  const deadlineDate = carData.inspection
    ? getWarrantyDeadline(carData.inspection).deadlineDate
    : undefined;

  const descriptions = useMemo(
    () =>
      [
        <>
          <b>186개 항목</b> 정밀 진단
        </>,
        <>
          1개월간 고장 수리비 <b>최대 500만원</b> 지원
        </>,
        deadlineDate ? (
          <>
            <b>{formatTemplates['1월 1일'](deadlineDate)}</b>까지 <b>안심직거래</b> 완료시 가입 가능
          </>
        ) : undefined,
      ].filter(nonNullish),
    [deadlineDate]
  );

  const handleClick = () => {
    push('warranty_guide', { articleId: id });
    track('article_click_warranty_section_more');
  };

  return (
    <VStack bc="divider2" br={6}>
      <VStack gap={16} px={16} py={20}>
        <VStack gap={6}>
          <WarrantyBadge size="small">
            <HStack align="center" gap={2.4}>
              <IconConfirmationFill {...getSizeProps(14.4)} color={getSeedColor('primary')} />
              <Caption2Bold color="carrot900">진단보증 패키지</Caption2Bold>
            </HStack>
          </WarrantyBadge>
          <Title3Bold>고장 수리비 지원 차량이에요</Title3Bold>
        </VStack>
        <VStack gap={9}>
          {descriptions.map((description, index) => (
            <HStack justify="space-between" key={index}>
              <BodyM2Regular color="gray700">{description}</BodyM2Regular>
              <IconCheckFill {...getSizeProps(20)} color={getSeedColor('success')} />
            </HStack>
          ))}
        </VStack>
      </VStack>
      <Divider />
      <VStack align="center" justify="center" onClick={handleClick} py={8}>
        <TextButton variant="secondary">
          <HStack align="center" gap={1} justify="center">
            <Label3Bold color="gray600">자세히 보기</Label3Bold>
            <IconChevronRightFill {...getSizeProps(14)} color={getSeedColor('gray600')} />
          </HStack>
        </TextButton>
      </VStack>
    </VStack>
  );
};

export default ArticleWarrantySection;
