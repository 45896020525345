import { ComponentType, LazyExoticComponent, lazy } from 'react';
export type ReactLazyFactory<T = any> = () => Promise<{ default: ComponentType<T> }>;

const factories: (() => void)[] = [];

export function preloadPageChunks() {
  for (const factory of factories) {
    requestIdleCallback(() => factory());
  }
}

export function lazyWithPreload<T = any>(
  factory: ReactLazyFactory<T>
): LazyExoticComponent<ComponentType<T>> {
  factories.push(factory);
  return lazy(factory);
}
/* write: 글쓰기/작성자 관련 페이지 */
export const LazyPageWriteArticle = lazyWithPreload(
  () => import(/* webpackChunkName: "write" */ '@/pages/WriteArticle')
);

export const LazyPageSelectSubseries = lazyWithPreload(
  () => import(/* webpackChunkName: "write" */ '@/pages/SelectSubseries')
);
export const LazyPageSelectSubgrade = lazyWithPreload(
  () => import(/* webpackChunkName: "write" */ '@/pages/SelectSubgrade')
);
export const LazyPageSelectSeries = lazyWithPreload(
  () => import(/* webpackChunkName: "write" */ '@/pages/SelectSeries')
);
export const LazyPageSelectGrade = lazyWithPreload(
  () => import(/* webpackChunkName: "write" */ '@/pages/SelectGrade')
);
export const LazyPageSelectCompany = lazyWithPreload(
  () => import(/* webpackChunkName: "write" */ '@/pages/SelectCompany')
);
export const LazyPageInputDisplacement = lazyWithPreload(
  () => import(/* webpackChunkName: "write" */ '@/pages/InputDisplacement')
);
export const LazyPageFetchCarInfo = lazyWithPreload(
  () => import(/* webpackChunkName: "write" */ '@/pages/FetchCarInfo')
);
export const LazyPageArticleEdit = lazyWithPreload(
  () => import(/* webpackChunkName: "write" */ '@/pages/ArticleEdit')
);

export const LazyPageArticleEditImages = lazyWithPreload(
  () => import(/* webpackChunkName: "write" */ '@/pages/ArticleEditImages')
);

/* feed: 메인 피드 관련 페이지 */
export const LazyPageLikedArticles = lazyWithPreload(
  () => import(/* webpackChunkName: "feed" */ '@/pages/LikedArticles')
);
export const LazyPageNotification = lazyWithPreload(
  () => import(/* webpackChunkName: "feed" */ '@/pages/Notification')
);
export const LazyPageNotificationArticles = lazyWithPreload(
  () => import(/* webpackChunkName: "feed" */ '@/pages/NotificationArticles')
);
export const LazyPageHotArticles = lazyWithPreload(
  () => import(/* webpackChunkName: "feed" */ '@/pages/HotArticles')
);
export const LazyPageInspectedArticles = lazyWithPreload(
  () => import(/* webpackChunkName: "feed" */ '@/pages/InspectedArticles')
);
export const LazyPageCuration = lazyWithPreload(
  () => import(/* webpackChunkName: "feed" */ '@/pages/Curation')
);

/* article: 매물 상세 관련 페이지 */
export const LazyPageArticleCarHistory = lazyWithPreload(
  () => import(/* webpackChunkName: "article" */ '@/pages/ArticleCarHistory')
);
export const LazyPageArticleImages = lazyWithPreload(
  () => import(/* webpackChunkName: "article" */ '@/pages/ArticleImages')
);
export const LazyPageArticleChannelList = lazyWithPreload(
  () => import(/* webpackChunkName: "article" */ '@/pages/ArticleChannelList')
);
export const LazyPageArticleOptions = lazyWithPreload(
  () => import(/* webpackChunkName: "article" */ '@/pages/ArticleOptions')
);
export const LazyPageSubseriesTransactionHistory = lazyWithPreload(
  () => import(/* webpackChunkName: "article" */ '@/pages/SubseriesTransactionHistory')
);
export const LazyPageArticleSummary = lazyWithPreload(
  () => import(/* webpackChunkName: "article" */ '@/pages/ArticleSummary')
);
export const LazyPageArticleProxyInspectionGuide = lazyWithPreload(
  () => import(/* webpackChunkName: "article" */ '@/pages/ArticleProxyInspectionGuide')
);
export const LazyPageArticleProxyInspectionPayment = lazyWithPreload(
  () => import(/* webpackChunkName: "article" */ '@/pages/ArticleProxyInspectionPayment')
);
export const LazyPageInspectionReport = lazyWithPreload(
  () => import(/* webpackChunkName: "inspection_report" */ '@/pages/InspectionReport')
);

export const LazyPageInspectionReportImages = lazyWithPreload(
  () => import(/* webpackChunkName: "inspection_report" */ '@/pages/InspectionReportImages')
);

export const LazyPageArticleSelectReserver = lazyWithPreload(
  () => import(/* webpackChunkName: "article_select_reserver" */ '@/pages/ArticleSelectReserver')
);
export const LazyPageArticleSelectBuyer = lazyWithPreload(
  () => import(/* webpackChunkName: "article_select_buyer" */ '@/pages/ArticleSelectBuyer')
);
export const LazyPageArticleLeaseRental = lazyWithPreload(
  () => import(/* webpackChunkName: "article_lease_rental" */ '@/pages/ArticleLeaseRental')
);
export const LazyPageArticleChecklist = lazyWithPreload(
  () => import(/* webpackChunkName: "article_checklist" */ '@/pages/ArticleChecklist')
);
export const LazyPageArticleChangePrice = lazyWithPreload(
  () => import(/* webpackChunkName: "article_change_price" */ '@/pages/ArticleChangePrice')
);
export const LazyPagePullUp = lazyWithPreload(
  () => import(/* webpackChunkName: "article_pull_up" */ '@/pages/PullUp')
);

/* search: 검색 관련 페이지 */
export const LazyPageSearchResult = lazyWithPreload(
  () => import(/* webpackChunkName: "search" */ '@/pages/Search/SearchResult')
);
export const LazyPageSearch = lazyWithPreload(
  () => import(/* webpackChunkName: "search" */ '@/pages/Search')
);

/* review: 후기 관련 페이지 */
export const LazyPageWriteTradeReview = lazyWithPreload(
  () => import(/* webpackChunkName: "review" */ '@/pages/WriteTradeReview')
);
export const LazyPageTradeReview = lazyWithPreload(
  () => import(/* webpackChunkName: "review" */ '@/pages/TradeReview')
);

export const LazyPageTradeGuide = lazyWithPreload(
  () => import(/* webpackChunkName: "trade_guide" */ '@/pages/TradeGuide')
);

export const LazyPageSalesHistory = lazyWithPreload(
  () => import(/* webpackChunkName: "sales_history" */ '@/pages/SalesHistory')
);

export const LazyPageSubseriesMetadata = lazyWithPreload(
  () => import(/* webpackChunkName: "metadata" */ '@/pages/SubseriesMetadata')
);

export const LazyPageSubmergedCar = lazyWithPreload(
  () => import(/* webpackChunkName: "submerged_car" */ '@/pages/SubmergedCar')
);

export const LazyPageTradedCars = lazyWithPreload(
  () => import(/* webpackChunkName: "sales_completed" */ '@/pages/TradedCars')
);
export const LazyPageNoAccident = lazyWithPreload(
  () => import(/* webpackChunkName: "no_accident" */ '@/pages/NoAccident')
);

export const LazyPageHyundaiCapital = lazyWithPreload(
  () => import(/* webpackChunkName: "hyundai_capital" */ '@/pages/HyundaiCapital')
);

export const LazyPageMyInspections = lazyWithPreload(
  () => import(/* webpackChunkName: "my_inspections" */ '@/pages/MyInspections')
);

export const LazyPageRegisterMechanic = lazyWithPreload(
  () => import(/* webpackChunkName: "register_inspector" */ '@/pages/RegisterMechanic')
);

export const LazyPageRegisterMechanicSuccess = lazyWithPreload(
  () => import(/* webpackChunkName: "register_inspector" */ '@/pages/RegisterMechanicSuccess')
);

export const LazyPageRegisterMechanicInputRegions = lazyWithPreload(
  () => import(/* webpackChunkName: "register_inspector" */ '@/pages/RegisterMechanicInputRegions')
);

export const LazyPageInputAddresses = lazyWithPreload(
  () => import(/* webpackChunkName: "register_inspector" */ '@/pages/InputAddresses')
);

export const LazyPageReserveInspection = lazyWithPreload(
  () => import(/* webpackChunkName: "reserve_inspection" */ '@/pages/ReserveInspection')
);

export const LazyPageReserveAgainInspection = lazyWithPreload(
  () =>
    import(/* webpackChunkName: "inspections" */ '@/pages/ReserveInspection/ReserveAgainInspection')
);

export const LazyPageInspectionReply = lazyWithPreload(
  () => import(/* webpackChunkName: "inspections" */ '@/pages/InspectionReply')
);

export const LazyPageInspectionGuide = lazyWithPreload(
  () => import(/* webpackChunkName: "reserve_inspection" */ '@/pages/InspectionGuide')
);
export const LazyPagePaymentSmokeTest = lazyWithPreload(
  () => import(/* webpackChunkName: "payment" */ '@/pages/PaymentSmokeTest')
);

export const LazyPageWarranty = lazyWithPreload(
  () => import(/* webpackChunkName: "warranty" */ '@/pages/Warranty')
);
export const LazyPageRegisterWarranty = lazyWithPreload(
  () => import(/* webpackChunkName: "warranty" */ '@/pages/RegisterWarranty')
);
export const LazyPageWarrantyPrivacyConsent = lazyWithPreload(
  () => import(/* webpackChunkName: "warranty" */ '@/pages/WarrantyPrivacyConsent')
);
export const LazyPageWarrantyTermsAgreement = lazyWithPreload(
  () => import(/* webpackChunkName: "warranty" */ '@/pages/WarrantyTermsAgreement')
);
export const LazyPageWarrantyCoverageExclusion = lazyWithPreload(
  () => import(/* webpackChunkName: "warranty" */ '@/pages/WarrantyCoverageExclusion')
);
export const LazyPageWarrantyGuide = lazyWithPreload(
  () => import(/* webpackChunkName: "warranty" */ '@/pages/WarrantyGuide')
);
export const LazyPageWarrantyExclusionGuide = lazyWithPreload(
  () => import(/* webpackChunkName: "warranty" */ '@/pages/WarrantyExclusionGuide')
);

export const LazyPageInspection = lazyWithPreload(
  () => import(/* webpackChunkName: "inspections" */ '@/pages/Inspection')
);
export const LazyPageInspectionFaqs = lazyWithPreload(
  () => import(/* webpackChunkName: "my_inspections" */ '@/pages/InspectionFaqs')
);
export const LazyPageInspectionMemo = lazyWithPreload(
  () => import(/* webpackChunkName: "my_inspections" */ '@/pages/InspectionMemo')
);
export const LazyPageInspectionReschedule = lazyWithPreload(
  () => import(/* webpackChunkName: "my_inspections" */ '@/pages/InspectionReschedule')
);
export const LazyPageInspectionCancel = lazyWithPreload(
  () => import(/* webpackChunkName: "my_inspections" */ '@/pages/InspectionCancel')
);

export const LazyPageDevLaboratory = lazyWithPreload(
  () => import(/* webpackChunkName: "dev_laboratory" */ '@/pages/DevLaboratory')
);
export const LazyPageTermsOfAgreement = lazyWithPreload(
  () => import(/* webpackChunkName: "terms_of_agreement" */ '@/pages/TermsOfAgreement')
);

export const LazyPageWriteReportContent = lazyWithPreload(
  () => import(/* webpackChunkName: "write_report" */ '@/pages/WriteReport/WriteReportContent')
);

export const LazyPageWriteReportItem = lazyWithPreload(
  () => import(/* webpackChunkName: "write_report" */ '@/pages/WriteReport/WriteReportItem')
);

export const LazyPageWriteReportConfirm = lazyWithPreload(
  () => import(/* webpackChunkName: "write_report" */ '@/pages/WriteReport/WriteReportConfirm')
);

export const LazyPageWriteReportObdCode = lazyWithPreload(
  () => import(/* webpackChunkName: "write_report" */ '@/pages/WriteReport/WriteReportObdCode')
);

export const LazyPageWriteReportReject = lazyWithPreload(
  () => import(/* webpackChunkName: "write_report" */ '@/pages/WriteReport/WriteReportReject')
);

export const LazyPageWriteReportCarData = lazyWithPreload(
  () => import(/* webpackChunkName: "write_report" */ '@/pages/WriteReport/WriteReportCarData')
);

export const LazyPageWriteReportOptions = lazyWithPreload(
  () => import(/* webpackChunkName: "write_report" */ '@/pages/WriteReport/WriteReportOptions')
);

export const LazyPageWriteReportBaseInfo = lazyWithPreload(
  () => import(/* webpackChunkName: "write_report" */ '@/pages/WriteReport/WriteReportBaseInfo')
);
export const LazyPageWriteReportView = lazyWithPreload(
  () => import(/* webpackChunkName: "write_report" */ '@/pages/WriteReport/WriteReportView')
);

export const LazyPageWriteReport = lazyWithPreload(
  () => import(/* webpackChunkName: "write_report" */ '@/pages/WriteReport')
);

export const LazyPageMoonJinPyo = lazyWithPreload(
  () => import(/* webpackChunkName: "write_report" */ '@/pages/MoonJinPyo')
);

export const LazyPageWriteMechanicReview = lazyWithPreload(
  () => import(/* webpackChunkName: "write_mechanic_review" */ '@/pages/WriteMechanicReview')
);

export const LazyPageMechanicTimeTable = lazyWithPreload(
  () => import(/* webpackChunkName: "write_mechanic_review" */ '@/pages/MechanicTimeTable')
);

export const LazyPageMechanic = lazyWithPreload(
  () => import(/* webpackChunkName: "write_mechanic_review" */ '@/pages/MechanicMyProfile')
);

export const LazyPageInspectionListForReview = lazyWithPreload(
  () => import(/* webpackChunkName: "inspection_review" */ '@/pages/InspectionsForReview')
);
export const LazyPageWriteReportApproval = lazyWithPreload(
  () =>
    import(/* webpackChunkName: "inspection_review" */ '@/pages/WriteReport/WriteReportApproval')
);
export const LazyPageInspectionPriceEstimation = lazyWithPreload(
  () => import(/* webpackChunkName: "inspection_review" */ '@/pages/InspectionPriceEstimation')
);
export const LazyPageInspectionEstimationReport = lazyWithPreload(
  () => import(/* webpackChunkName: "inspection_review" */ '@/pages/InspectionEstimationReport')
);

export const LazyPageInspectionFinalizeArticle = lazyWithPreload(
  () =>
    import(
      /* webpackChunkName: "inspection_finalize_article" */ '@/pages/InspectionFinalizeArticle'
    )
);

export const LazyPageInspectionAttachDealerProposal = lazyWithPreload(
  () =>
    import(
      /* webpackChunkName: "InspectionAttachDealerProposal" */ '@/pages/InspectionAttachDealerProposal'
    )
);

export const LazyPageInspectionCarHistory = lazyWithPreload(
  () => import(/* webpackChunkName: "inspections" */ '@/pages/InspectionCarHistory')
);

export const LazyPageSafeTradeGuide = lazyWithPreload(
  () => import(/* webpackChunkName: "ArticleSafeTrade" */ '@/pages/ArticleSafeTrade/SafeTradeGuide')
);

export const LazyPageArticleSafeTrade = lazyWithPreload(
  () => import(/* webpackChunkName: "ArticleSafeTrade" */ '@/pages/ArticleSafeTrade')
);

export const LazyPageArticleSafeTradeBuyerConfirmTrade = lazyWithPreload(
  () =>
    import(
      /* webpackChunkName: "ArticleSafeTrade" */ '@/pages/ArticleSafeTrade/ArticleSafeTradeBuyerConfirmTrade'
    )
);

export const LazyPageArticleSafeTradeBuyerCompletePayment = lazyWithPreload(
  () =>
    import(
      /* webpackChunkName: "ArticleSafeTrade" */ '@/pages/ArticleSafeTrade/ArticleSafeTradeBuyerCompletePayment'
    )
);

export const LazyPageArticleSafeTradeBuyerConfirmPrice = lazyWithPreload(
  () =>
    import(
      /* webpackChunkName: "ArticleSafeTrade" */ '@/pages/ArticleSafeTrade/ArticleSafeTradeBuyerConfirmPrice'
    )
);

export const LazyPageArticleSafeTradeBuyerInputPrice = lazyWithPreload(
  () =>
    import(
      /* webpackChunkName: "ArticleSafeTrade" */ '@/pages/ArticleSafeTrade/ArticleSafeTradeBuyerInputPrice'
    )
);

export const LazyPageArticleSafeTradeRegisterAccount = lazyWithPreload(
  () =>
    import(
      /* webpackChunkName: "ArticleSafeTrade" */ '@/pages/ArticleSafeTrade/ArticleSafeTradeRegisterAccount'
    )
);

export const LazyPageArticleSafeTradeSellerCancelTrade = lazyWithPreload(
  () =>
    import(
      /* webpackChunkName: "ArticleSafeTrade" */ '@/pages/ArticleSafeTrade/ArticleSafeTradeSellerCancelTrade'
    )
);

export const LazyPageArticleSafeTradeSellerConfirmPrice = lazyWithPreload(
  () =>
    import(
      /* webpackChunkName: "ArticleSafeTrade" */ '@/pages/ArticleSafeTrade/ArticleSafeTradeSellerConfirmPrice'
    )
);
export const LazyPageAgreement = lazyWithPreload(
  () => import(/* webpackChunkName: "Agreement" */ '@/pages/Agreement')
);
