import type { AppScreenProps } from '@stackflow/plugin-basic-ui';
import type { ActivityComponentType } from '@stackflow/react';

import { z } from 'zod';

import { zDecoder } from '@/stackflow/zDecoder';
import { WriteStep } from '@/store/write';
import { AgreementId } from '@/types/Agreement';
import { CompanyOrigin } from '@/types/CarData/CompanyOrigin';
import { ReservationStep } from '@/types/ReserveInspection/ReservationStep';

type DefaultAppScreenProps = Pick<
  AppScreenProps,
  'CUPERTINO_ONLY_modalPresentationStyle' | 'preventSwipeBack'
>;

export const activityDescription = {
  article: {
    schema: z
      .object({
        articleId: zDecoder.str(),
        action: zDecoder.str().optional(),
        afterWriteArticle: zDecoder.bool().optional(),
        afterWriteWarrantyArticle: zDecoder.bool().optional(),
      })
      .passthrough(),
    url: '/articles/:articleId',
    description: '매물 상세',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  article_car_history: {
    schema: z
      .object({
        articleId: zDecoder.str(),
        to: zDecoder.str().optional(),
      })
      .passthrough(),
    url: '/articles/:articleId/car_history',
    description: '게시글 -> 카 히스토리 상세',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  inspection_car_history: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/car_history',
    description: '진단 -> 카 히스토리 상세',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  article_change_price: {
    schema: z
      .object({
        articleId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/change_price',
    description: '가격 변경',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  article_channel_list: {
    schema: z
      .object({
        articleId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/channel_list',
    description: '매물 채팅 목록',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  article_checklist: {
    schema: z
      .object({
        articleId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/checklist',
    description: '매물 체크리스트',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  article_images: {
    schema: z
      .object({
        articleId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/images',
    description: '매물 이미지 목록',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  article_lease_rental: {
    schema: z
      .object({
        articleId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/lease_rental',
    description: '렌트/리스 상세',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  article_options_list: {
    schema: z
      .object({
        articleId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/options',
    description: '매물 옵션 상세',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  article_select_buyer: {
    schema: z
      .object({
        articleId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/select_buyer',
    description: '구매자 지정',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  article_select_reserver: {
    schema: z
      .object({
        articleId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/select_reserver',
    description: '예약자 지정',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  article_summary: {
    schema: z
      .object({
        articleId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/summary',
    description: '거래 내역 상세',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  article_proxy_inspection_guide: {
    schema: z
      .object({
        articleId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/proxy_inspection_guide',
    description: '출장진단 가이드',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  article_proxy_inspection_payment: {
    schema: z
      .object({
        articleId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/proxy_inspection_payment',
    description: '출장진단 결제',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  curation: {
    schema: z
      .object({
        curationId: zDecoder.str(),
      })
      .passthrough(),
    url: '/curation/:curationId',
    description: '큐레이션',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  dev_laboratory: {
    schema: z.object({}).passthrough(),
    url: '/dev_laboratory',
    description: '개발 실험실',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  edit_article: {
    schema: z
      .object({
        articleId: zDecoder.str(),
        step: zDecoder.nativeEnum(WriteStep).optional(),
      })
      .passthrough(),
    url: '/articles/:articleId/edit',
    description: '글 수정',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  edit_article_images: {
    schema: z
      .object({
        articleId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/edit_images',
    description: '글 수정',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  fetch_car_info: {
    schema: z
      .object({
        articleId: zDecoder.str().optional(),
      })
      .passthrough(),
    url: '/fetch_car_info',
    description: '차량 조회',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  hot_articles: {
    schema: z.object({}).passthrough(),
    url: '/hot_articles',
    description: '지금 인기있는 매물',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  inspected_articles: {
    schema: z.object({}).passthrough(),
    url: '/inspected_articles',
    description: '진단 매물 목록',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  hyundai_capital: {
    schema: z.object({}).passthrough(),
    url: '/promotions/hyundai_capital',
    description: '현대캐피탈',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  input_displacement: {
    schema: z.object({}).passthrough(),
    url: '/input_displacement',
    description: '배기량 입력',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  inspection: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId',
    description: '진단 조회',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  inspection_memo: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/memo',
    description: '진단사 - 진단 관련 메모',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  inspection_reschedule: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/reschedule',
    description: '진단사 - 진단 시간 변경',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  inspection_cancel: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/cancel',
    description: '진단사 - 진단 취소',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  inspection_attach_dealer_proposal: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/attach_dealer_proposal',
    description: '진단 예약 후 딜러 제안가 입력하는 페이지',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  inspection_guide: {
    schema: z
      .object({
        carNo: zDecoder.str(),
        ownerName: zDecoder.str(),
      })
      .passthrough(),
    url: '/inspection_guide',
    description: '사전 진단 혜택',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  warranty: {
    schema: z.object({
      articleId: zDecoder.str(),
    }),
    url: '/articles/:articleId/warranty',
    description: '진단보증 패키지 > 보험가입확인서',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  register_warranty: {
    schema: z
      .object({
        articleId: zDecoder.str(),
        safeTradeId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/safe_trade/:safeTradeId/register_warranty',
    description: '진단보증 패키지 > 가입/결제',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  warranty_privacy_consent: {
    schema: z.object({}).passthrough(),
    url: '/warranty_privacy_consent',
    description: '진단보증 패키지 > 개인정보 제공 동의',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  warranty_terms_agreement: {
    schema: z
      .object({
        articleId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/warranty_terms_agreement',
    description: '진단보증 패키지 > 약관 동의',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  warranty_coverage_exclusion: {
    schema: z.object({}).passthrough(),
    url: '/warranty_coverage_exclusion',
    description: '진단보증 패키지 > 보증 제외 항목',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  warranty_guide: {
    schema: z
      .object({
        articleId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/warranty_guide',
    description: '진단보증 패키지 > 가이드',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  warranty_exclusion_guide: {
    schema: z.object({}).passthrough(),
    url: '/warranty_exclusion_guide',
    description: '[DEPRECATED] 보증 안내 - 보장 제외 대상',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  main: {
    schema: z.object({}).passthrough(),
    url: '/',
    description: '홈 피드',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  my_sales_history: {
    schema: z.object({}).passthrough(),
    url: '/my_sales_history',
    description: '판매내역',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  no_accident: {
    schema: z.object({}).passthrough(),
    url: '/faqs/no_accident',
    description: '무사고 정의',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  notification: {
    schema: z.object({}).passthrough(),
    url: '/notification',
    description: '새 글 알림 목록',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  notification_articles: {
    schema: z
      .object({
        title: zDecoder.str(),
        notificationId: zDecoder.str(),
      })
      .passthrough(),
    url: '/notifications/:notificationId/articles',
    description: '새글 알림 필터 - 게시글 목록',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  privacy_policy: {
    schema: z.object({}).passthrough(),
    url: '/privacy_policy',
    description: '개인정보 제공 동의',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  pullup_article: {
    schema: z
      .object({
        articleId: zDecoder.str(),
        state: z.enum(['confetti', 'payment', 'suggest_promotion'] as const).optional(),
      })
      .passthrough(),
    url: '/articles/:articleId/pull_up',
    description: '끌올',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  reserve_inspection: {
    schema: z
      .object({
        step: zDecoder.nativeEnum(ReservationStep).optional(),
        carNo: zDecoder.str(),
        ownerName: zDecoder.str(),
      })
      .passthrough(),
    url: '/inspections/reserve',
    description: '글 쓰기 - 판매자 사전 진단 - 정보 입력',
    defaultAppScreenProps: {
      preventSwipeBack: true,
    } as DefaultAppScreenProps,
  },
  reserve_inspection_again: {
    schema: z
      .object({
        step: zDecoder.nativeEnum(ReservationStep).optional(),
        inspectionId: zDecoder.str(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/reserve_again',
    description: '진단 상세 - 진단 변경하기 페이지',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  sales_history: {
    schema: z.object({}).passthrough(),
    url: '/sales_history',
    description: '판매내역 (중고거래 통합, deprecated)',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  search: {
    schema: z
      .object({
        companyIds: zDecoder.strArray().optional(),
        seriesIds: zDecoder.strArray().optional(),
        subseriesIds: zDecoder.strArray().optional(),
        keyword: zDecoder.str().optional(),
      })
      .passthrough(),
    url: '/search',
    description: '검색',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  search_result: {
    schema: z
      .object({
        companyOrigin: zDecoder.nativeEnum(CompanyOrigin).optional(),
        companyIds: zDecoder.strArray().optional(),
        seriesIds: zDecoder.strArray().optional(),
        subseriesIds: zDecoder.strArray().optional(),
        keyword: zDecoder.str().optional(),
      })
      .passthrough(),
    url: '/search_result',
    description: '검색 결과',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  select_company: {
    schema: z.object({}).passthrough(),
    url: '/select_company',
    description: '제조사 선택',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  select_grade: {
    schema: z
      .object({
        subseriesId: zDecoder.str(),
      })
      .passthrough(),
    url: '/select_grade/:subseriesId',
    description: '등급 선택',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  select_series: {
    schema: z
      .object({
        companyId: zDecoder.str(),
      })
      .passthrough(),
    url: '/select_series/:companyId',
    description: '시리즈 선택',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  select_subgrade: {
    schema: z
      .object({
        gradeId: zDecoder.str(),
      })
      .passthrough(),
    url: '/select_subgrade/:gradeId',
    description: '세부등급 선택',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  select_subseries: {
    schema: z
      .object({
        seriesId: zDecoder.str(),
      })
      .passthrough(),
    url: '/select_subseries/:seriesId',
    description: '세부시리즈 선택',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  submerged_car: {
    schema: z.object({}).passthrough(),
    url: '/faqs/submerged_car',
    description: '침수차 가이드',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  subseries_metadata: {
    schema: z
      .object({
        subseriesId: zDecoder.str(),
      })
      .passthrough(),
    url: '/subseries/:subseriesId',
    description: '세부시리즈 메타데이터',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  subseries_transaction_history: {
    schema: z
      .object({
        subseriesId: zDecoder.str(),
        articleId: zDecoder.str(),
      })
      .passthrough(),
    url: '/subseries/:subseriesId/transaction_history',
    description: '거래 내역 목록',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  trade_guide: {
    schema: z
      .object({
        scrollTarget: zDecoder.enum(['check', 'search', 'register'] as const).optional(),
      })
      .passthrough(),
    url: '/faqs/trade_guide',
    description: '직거래 가이드',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  trade_review: {
    schema: z
      .object({
        tradeReviewId: zDecoder.str(),
        action: zDecoder.enum(['check_status'] as const).optional(),
      })
      .passthrough(),
    url: '/trade_reviews/:tradeReviewId',
    description: '거래후기',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  traded_cars: {
    schema: z.object({}).passthrough(),
    url: '/traded_cars',
    description: '거래완료 차량 목록',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  write_article: {
    schema: z
      .object({
        step: zDecoder.nativeEnum(WriteStep).optional(),
      })
      .passthrough(),
    url: '/articles/new',
    description: '글 쓰기',
    defaultAppScreenProps: {
      preventSwipeBack: true,
    } as DefaultAppScreenProps,
  },
  write_trade_review: {
    schema: z
      .object({
        articleId: zDecoder.str(),
        receiverId: zDecoder.str(),
        rating: zDecoder.enum(['BAD', 'EXCELLENT', 'GOOD', 'NORMAL'] as const).optional(),
      })
      .passthrough(),
    url: '/articles/:articleId/write_trade_review/:receiverId',
    description: '거래후기 작성',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  liked_articles: {
    schema: z.object({}).passthrough(),
    url: '/liked_articles',
    description: '관심 중고차',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  register_mechanic: {
    schema: z.object({}).passthrough(),
    url: '/register_mechanic',
    description: '정비사 등록하기',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  register_mechanic_input_regions: {
    schema: z
      .object({
        initialRegions: zDecoder.strArray().optional(),
      })
      .passthrough(),
    url: '/register_mechanic/input_regions',
    description: '정비사 등록하기 - 지역 입력',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  input_addresses: {
    schema: z.object({}).passthrough(),
    url: '/input_addresses',
    description: '정비사 등록하기 - 주소 입력',
    defaultAppScreenProps: {
      CUPERTINO_ONLY_modalPresentationStyle: 'fullScreen',
      preventSwipeBack: true,
    } as DefaultAppScreenProps,
  },
  register_mechanic_success: {
    schema: z.object({}).passthrough(),
    url: '/register_mechanic/success',
    description: '정비사 등록하기 - 등록 성공',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  my_inspections: {
    schema: z
      .object({
        tab: zDecoder.enum(['approved', 'completed', 'requested'] as const).optional(),
      })
      .passthrough(),
    url: '/my_inspections',
    description: '정비사 - 내 예약 관리',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  terms_of_agreement: {
    schema: z.object({}).passthrough(),
    url: '/terms_of_agreement',
    description: '약관 동의',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  write_report_content: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
        reportId: zDecoder.str().optional(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/write_report/content',
    description: '차량 진단 작성',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  write_report_item: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
        category: zDecoder.str(),
        group: zDecoder.str(),
        invalidPart: zDecoder.str().optional(),
        reportId: zDecoder.str().optional(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/write_report/item',
    description: '차량 진단 작성 - 진단 항목 작성',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  write_report_obd_code: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
        reportId: zDecoder.str().optional(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/write_report/obd_code',
    description: '차량 진단 작성 - 진단 항목 작성',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  write_report_confirm: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
        reportId: zDecoder.str().optional(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/write_report/confirm',
    description: '차량 진단 작성 - 확인',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  write_report_reject: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
        reportId: zDecoder.str().optional(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/write_report/reject',
    description: '차량 진단 작성 - 거절',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  write_report_car_data: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
        reportId: zDecoder.str().optional(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/write_report/car_data',
    description: '차량 진단 작성 - 차량 데이터 변경',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  write_report_options: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
        reportId: zDecoder.str().optional(),
        tab: zDecoder.str().optional(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/write_report/options',
    description: '차량 진단 작성 - 차량 옵션 입력',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  write_report_base_info: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
        reportId: zDecoder.str().optional(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/write_report/base_info',
    description: '차량 진단 작성 - 차량 옵션 입력',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  write_report: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
        reportId: zDecoder.str().optional(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/write_report',
    description: '차량 진단 작성 - 진입점',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  // TODO: 적절한 이름 찾아보기
  write_report_view: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
        reportId: zDecoder.str().optional(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/write_report/view',
    description: '차량 진단 작성 - 작성 후 상세보기 (진단사 본인 진단 내용 확인용)',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  write_report_approval: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/write_report/approval',
    description: '차량 진단 - 진단 리포트 내용 승인 (검수자용)',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  inspection_report: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
        selectedTirePart: zDecoder.enum(['front', 'rear'] as const).optional(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/report',
    description: '차량 상태 - 사용자 향 차량 진단 보고서',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  inspection_report_images: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
        scrollTarget: zDecoder.enum(['interior', 'exterior'] as const).optional(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/report/images',
    description: '차량 상태 - 사용자 향 차량 진단 보고서 - 이미지',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  moon_jin_pyo: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/moon_jin_pyo',
    description: '진단 - 문진표',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  write_mechanic_review: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/write_mechanic_review',
    description: '진단사 리뷰 작성',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  mechanic_my_profile: {
    schema: z.object({}).passthrough(),
    url: '/mechanic/my_profile',
    description: '진단사 - 내 프로필',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  mechanic_time_table: {
    schema: z
      .object({
        dayIndex: zDecoder.str(),
      })
      .passthrough(),
    url: '/mechanic/my_time_table',
    description: '진단사 - 타임 테이블 편집',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  payment_smoke_test: {
    schema: z
      .object({
        title: zDecoder.str(),
        price: zDecoder.str(),
        actionLabel: zDecoder.str().optional(),
        calloutText: zDecoder.str().optional(),
      })
      .passthrough(),
    url: '/payment_smoke_test',
    description: '결제 스모크 테스트',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  inspection_faqs: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/faqs',
    description: '당근 진단 FAQ',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  inspections_for_review: {
    schema: z.object({}).passthrough(),
    url: '/inspections_for_review',
    description: '진단 리포트 목록 - 검수용',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  inspection_price_estimation: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/price_estimation',
    description: '진단 - 가치 평가',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  inspection_finalize_article: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/finalize_article',
    description: '진단 - 가격/추가 정보 입력 및 매물 등록',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  inspection_estimation_report: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/estimation_report',
    description: '진단 - 가치 평가 리포트',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  article_safe_trade: {
    schema: z
      .object({
        articleId: zDecoder.str(),
        safeTradeId: zDecoder.str(),
        nextEvent: zDecoder.str().optional(),
      })
      .passthrough(),
    url: '/articles/:articleId/safe_trade/:safeTradeId',
    description: '게시글 - 안전 직거래',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  article_safe_trade_register_account: {
    schema: z
      .object({
        articleId: zDecoder.str(),
        safeTradeId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/safe_trade/:safeTradeId/register_account',
    description: '게시글 - 안전 직거래 - 계좌 입력',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  article_safe_trade_buyer_complete_payment: {
    schema: z
      .object({
        articleId: zDecoder.str(),
        safeTradeId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/safe_trade/:safeTradeId/buyer_complete_payment',
    description: '게시글 - 안전 직거래 - 구매자 가상계좌 발급 완료',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  article_safe_trade_buyer_confirm_trade: {
    schema: z
      .object({
        articleId: zDecoder.str(),
        safeTradeId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/safe_trade/:safeTradeId/buyer_complete_trade',
    description: '게시글 - 안전 직거래 - 구매자 거래 완료',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  article_safe_trade_seller_cancel_trade: {
    schema: z
      .object({
        articleId: zDecoder.str(),
        safeTradeId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/safe_trade/:safeTradeId/seller_cancel_trade',
    description: '게시글 - 안전 직거래 - 판매자 거래 취소',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  article_safe_trade_buyer_input_price: {
    schema: z
      .object({
        articleId: zDecoder.str(),
        safeTradeId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/safe_trade/:safeTradeId/buyer_input_price',
    description: '게시글 - 안전 직거래 - 구매자의 차량 가액 입력',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  article_safe_trade_buyer_confirm_price: {
    schema: z
      .object({
        articleId: zDecoder.str(),
        safeTradeId: zDecoder.str(),
        price: zDecoder.num(), // 만원 단위
      })
      .passthrough(),
    url: '/articles/:articleId/safe_trade/:safeTradeId/buyer_confirm_price',
    description: '게시글 - 안전 직거래 - 구매자의 가격 확인 및 계좌 입금',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  article_safe_trade_seller_confirm_price: {
    schema: z
      .object({
        articleId: zDecoder.str(),
        safeTradeId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/safe_trade/:safeTradeId/seller_confirm_price',
    description: '게시글 - 안전 직거래 - 판매자 가격 확인',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  safe_trade_guide: {
    schema: z
      .object({
        articleId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/safe_trade_guide',
    description: '게시글 - 안전 직거래 - 판매자 가격 확인',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  agreement: {
    schema: z
      .object({
        agreementId: zDecoder.nativeEnum(AgreementId),
      })
      .passthrough(),
    url: '/agreement/:agreementId',
    description: '약관 동의 (agreementId에 따라 달라져요)',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  not_found: {
    schema: z.object({}).passthrough(),
    url: '*',
    description: '404 페이지',
    defaultAppScreenProps: {} as DefaultAppScreenProps,
  },
  inspection_reply: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
        step: zDecoder.nativeEnum(ReservationStep).optional(),
        actionType: zDecoder.enum(['approve', 'reject'] as const).optional(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/reply',
    description: '글 쓰기 - 판매자 사전 진단 - 정보 입력',
    defaultAppScreenProps: {
      preventSwipeBack: true,
    } as DefaultAppScreenProps,
  },
};

export type ActivityName = keyof typeof activityDescription;

export type ActivityParams<TActivityName extends ActivityName> = z.output<
  (typeof activityDescription)[TActivityName]['schema']
>;

export type CarActivities = {
  [key in ActivityName]: ActivityComponentType<ActivityParams<key>>;
};
