import { BoxButton } from '@daangn/sprout-components-button';
import { css } from '@emotion/react';
import { rem } from 'polished';
import React, { useRef } from 'react';
import { graphql, useFragment } from 'react-relay';
import { P, match } from 'ts-pattern';

import { ArticleCarHistorySection_article$key } from '@/__generated__/ArticleCarHistorySection_article.graphql';
import Text from '@/components/Base/Text';
import VStack from '@/components/Base/VStack';
import { useEffectOnce } from '@/hooks/useEffectOnce';
import { useToast } from '@/hooks/useToast';
import useTrack from '@/hooks/useTrack';
import SectionTitle from '@/pages/Article/components/SectionTitle';
import useGetCarHistory from '@/pages/Article/hooks/useGetCarHistory';
import { useFlow } from '@/stackflow';
import { useCamelCaseParams } from '@/utils/url';

import HistoryGrid, { CarHistoryItem } from './HistoryGrid';

type Props = {
  articleRef: ArticleCarHistorySection_article$key;
};

const carHistoryReferrerValues = ['car-history-added', 'car-history-requested'];

const ArticleCarHistorySection: React.FC<React.PropsWithChildren<Props>> = ({ articleRef }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { push } = useFlow();
  const { referrer } = useCamelCaseParams();
  const { setToast } = useToast();

  const {
    id,
    carData: { carHistoryStatus, carHistory },
  } = useFragment(
    graphql`
      fragment ArticleCarHistorySection_article on Article {
        id
        carData {
          carHistoryStatus
          carHistory {
            ...HistoryGrid_carHistory
          }
        }
      }
    `,
    articleRef
  );
  const [getCarHistory, getCarHistoryIsInFlight] = useGetCarHistory();

  const isCarHistoryExist = carHistoryStatus === 'OWNED' || !!carHistory;
  const { track } = useTrack();

  const handleClickGetCarHistory = async () => {
    try {
      const carHistory = await getCarHistory({ id });

      if (carHistory) {
        push('article_car_history', { articleId: id });
      }
    } catch (e) {
      setToast('보험이력 정보 등록에 실패했어요. 잠시 후 다시 시도해 주세요.');
    } finally {
      track('article_click_get_car_history');
    }
  };

  const handleClickMore = () => {
    push('article_car_history', { articleId: id });
    track('article_click_more');
  };

  useEffectOnce(() => {
    if (!carHistoryReferrerValues.includes(referrer ?? '')) {
      return;
    }
    containerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  });

  return (
    <VStack ref={containerRef}>
      <SectionTitle
        css={css`
          margin-bottom: ${rem(isCarHistoryExist ? 20 : 8)};
        `}
        onClickMore={isCarHistoryExist ? handleClickMore : undefined}
      >
        보험이력
      </SectionTitle>
      {match(carHistoryStatus)
        .with('OWNED', () => {
          const handleClick = (name: CarHistoryItem) => {
            push('article_car_history', { articleId: id, to: name });
            track('article_click_move_to_car_history_section', { to: name });
          };

          if (!carHistory) {
            return null;
          }

          return <HistoryGrid carHistoryRef={carHistory} onClick={handleClick} />;
        })
        .with(P.union('EXPIRED', 'NOT_REQUESTED'), () => (
          <VStack>
            <Text color="gray900" mb={12} variant="bodyL1Regular">
              보험이력 정보가 만료되었거나 등록되지 않았어요.
            </Text>
            <BoxButton
              isDisabled={getCarHistoryIsInFlight}
              onClick={() => {
                handleClickGetCarHistory();
                track('article_click_open_agreement');
              }}
              size="medium"
              variant="primaryLow"
            >
              조회하기
            </BoxButton>
          </VStack>
        ))
        .with(P.union('REQUESTED', 'UNABLE_TO_FETCH'), () => (
          <VStack>
            <Text color="gray900" mb={12} variant="bodyL1Regular">
              보험 이력을 불러오는 데 시간이 걸리고 있어요.
              <br />
              등록이 완료되면 알려드릴게요.
            </Text>
          </VStack>
        ))
        .exhaustive()}
    </VStack>
  );
};

export default ArticleCarHistorySection;
