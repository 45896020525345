import { Modal } from '@daangn/karrot-clothes';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { IconClose } from '@stackflow/plugin-basic-ui';
import { rem } from 'polished';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import SwiperCore from 'swiper';
import { Pagination, Zoom } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/scss/pagination';
import 'swiper/scss/zoom';

import Center from '@/components/Base/Center';
import NormalImage from '@/components/Base/OptimizedImage/NormalImage';
import VStack from '@/components/Base/VStack';
import { SafeAreaInsetBottomDiv, SafeAreaInsetTopDiv, safeAreaInset } from '@/styles/mixins';
import { Image } from '@/types/Image/Image';

import HStack from '../Base/HStack';

type ImageOrSrc = Pick<Image, 'url'> | string;

interface Props {
  images: ImageOrSrc[];
  initialIndex?: number;
  isOpen?: boolean;
  onClose: () => void;
  renderContent?: (i: number) => ReactNode;
  renderTopRight?: () => ReactNode;
}

const ImageDetailSlider: React.FC<React.PropsWithChildren<Props>> = ({
  images,
  initialIndex,
  isOpen,
  onClose,
  renderTopRight,
  renderContent,
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(initialIndex ?? 0);
  const topRightNode = renderTopRight ? renderTopRight() : undefined;
  const [isZoomed, setIsZoomed] = useState(false);
  const touchStartInfoRef = useRef([0, 0]);
  const contentNode = renderContent ? renderContent(activeIndex) : undefined;

  const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>): undefined => {
    if (isZoomed || e.changedTouches.length !== 1) {
      return undefined;
    }
    const endPoint = e.changedTouches[0].clientY;
    const [startPoint, startTimeStamp] = touchStartInfoRef.current;
    const pointDiff = endPoint - startPoint;
    const timeDiff = Date.now() - startTimeStamp;
    if (timeDiff < 1000 * 1.5 && pointDiff > 200) {
      onClose();
    }
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>): undefined => {
    if (e.touches.length !== 1) {
      return undefined;
    }
    touchStartInfoRef.current = [e.touches[0].clientY, Date.now()];
  };

  const handleSlideChange = (swiper: SwiperCore) => {
    setActiveIndex(swiper.activeIndex);
  };

  useEffect(() => {
    if (isOpen) {
      setActiveIndex(initialIndex ?? 0);
    }
  }, [initialIndex, isOpen]);

  return (
    <Modal onClose={onClose} open={isOpen ?? false}>
      <Base onTouchEnd={handleTouchEnd} onTouchStart={handleTouchStart}>
        <GradientVStack w="100%">
          <SafeAreaInsetTopDiv />
          <Header>
            <CloseButton onClick={onClose} px={4} py={4}>
              <IconClose />
            </CloseButton>
            {topRightNode && <TopRight>{topRightNode}</TopRight>}
          </Header>
          {contentNode}
        </GradientVStack>
        {isOpen && (
          <Swiper
            grabCursor
            initialSlide={initialIndex}
            modules={[Pagination, Zoom]}
            onSlideChange={handleSlideChange}
            onZoomChange={(_, scale) => {
              setIsZoomed(scale > 1);
            }}
            pagination={{ clickable: true }}
            speed={500}
            zoom={true}
          >
            {images.map((image, i) => {
              return (
                <SwiperSlide key={i}>
                  <ImageWrapper className="swiper-zoom-container">
                    <NormalImage src={typeof image === 'string' ? image : image.url} />
                  </ImageWrapper>
                </SwiperSlide>
              );
            })}
            <SwiperBottomGradient>
              <SafeAreaInsetBottomDiv />
            </SwiperBottomGradient>
          </Swiper>
        )}
      </Base>
    </Modal>
  );
};

const Base = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  background: ${vars.$static.color.staticBlack};

  & .swiper-slide {
    overflow: hidden;
  }

  & .swiper-pagination-bullets.swiper-pagination-bullets {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: ${rem(32)};
    ${safeAreaInset({ bottom: 0 })};
  }

  & .swiper-pagination-bullet {
    width: ${rem(6)};
    height: ${rem(6)};
    background: ${vars.$static.color.staticWhiteAlpha200};
    opacity: 1;
    position: relative;
    &::after {
      width: ${rem(12)};
      height: ${rem(12)};
      position: absolute;
      left: ${rem(-3)};
      top: ${rem(-3)};
      content: '';
    }
  }

  & .swiper-pagination-bullet-active {
    background: ${vars.$static.color.staticWhite};
  }
`;

const ImageWrapper = styled.div`
  height: 100vh !important;
  img {
    width: 100%;
  }
`;

const GradientVStack = styled(VStack)`
  top: 0;
  position: absolute;
  z-index: 5;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.15) calc(100% - ${rem(32)}),
    rgba(0, 0, 0, 0) 100%
  );

  &::after {
    height: ${rem(32)};
    width: 100%;
    display: block;
    content: '';
  }
`;

const SwiperBottomGradient = styled.div`
  bottom: 0;
  width: 100%;
  padding-top: ${rem(24)};
  position: absolute;
  z-index: 1;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.15) calc(100% - ${rem(24)}),
    rgba(0, 0, 0, 0) 100%
  );
  &::before {
    height: ${rem(24)};
    width: 100%;
    display: block;
    content: '';
  }
`;

const Header = styled.div({
  position: 'relative',
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  width: '100%',
  // stackflow ui 컨테이너 안쪽이 아니라 body에 렌더링되서 하드코딩.
  // var(--stackflow-plugin-basic-ui-app-bar-height)
  height: '2.75rem',
});

const CloseButton = styled(Center)({
  position: 'absolute',
  left: rem(10),
  top: '50%',
  transform: 'translateY(-50%)',
  color: vars.$static.color.staticWhite,
});

const TopRight = styled(HStack)({
  position: 'absolute',
  right: rem(12),
  top: '50%',
  transform: 'translateY(-50%)',
});

export default ImageDetailSlider;
