import type {
  CreateNewArticleNotificationConditionV2Input,
  H3Resolution,
} from '@/__generated__/useCreateNotification_CreateNewArticleNotificationConditionMutation.graphql';
import type { Region } from '@/sdk/bridge/info';
import type { Distance } from '@/utils/map/allDistances';

import { useFilterContext } from '@/components/Filter/FilterContextProvider';
import useUser from '@/hooks/useUser';
import { getMinimumBoundaryPolygonByDistance } from '@/utils/map/getMinimumBoundaryPolygonByDistance';
import { getPolygonCells } from '@/utils/map/getPolygonCells';
import { getResolutionByDistance } from '@/utils/map/getResolutionByDistance';
import { nullify } from '@/utils/misc';

type Props = {
  companyIds?: string[];
  seriesIds?: string[];
  subseriesIds?: string[];
};

export const distanceToRegionInfoInput = (
  region: Region,
  distance?: Distance
): Pick<
  CreateNewArticleNotificationConditionV2Input,
  'baseDistanceKm' | 'baseRegionId' | 'h3IndexList'
> => {
  if (!distance || distance === 999) {
    return {};
  }
  return {
    baseDistanceKm: distance,
    baseRegionId: region.id,
    h3IndexList: {
      h3Index: getPolygonCells(
        getMinimumBoundaryPolygonByDistance(region.centerCoordinates, distance),
        distance
      ),
      resolution: `RESOLUTION_${getResolutionByDistance(distance)}` as H3Resolution,
    },
  };
};

const useSelectedCondition = ({ companyIds = [], seriesIds = [], subseriesIds = [] }: Props) => {
  const { filter } = useFilterContext();
  const { region } = useUser();
  const {
    carTypes,
    companyOrigin,
    distance,
    driveDistanceMax,
    driveDistanceMin,
    fuelTypes,
    gears,
    modelYearMax,
    modelYearMin,
    priceMax,
    priceMin,
    saleTypes,
    // warrantyScope,
    companyIds: _companyIds,
  } = filter;

  const selectedCondition: CreateNewArticleNotificationConditionV2Input = {
    carTypes,
    companyOrigin,
    driveDistanceMax,
    driveDistanceMin,
    fuelTypes,
    gears,
    modelYearMax,
    modelYearMin,
    priceMax,
    priceMin,
    saleTypes,
    // warrantyScope,
    companyIds: nullify(companyIds ?? _companyIds),
    seriesIds: nullify(seriesIds),
    subseriesIds: nullify(subseriesIds),
    ...distanceToRegionInfoInput(region, distance ?? undefined),
  };

  return selectedCondition;
};

export default useSelectedCondition;
