import { useLayoutEffect } from 'react';

import { AppBarProps, usePageContext } from '@/components/Base/PageLayout';

export type ScreenHelmetProps = {
  preventSwipeBack?: boolean;
} & AppBarProps;

const ScreenHelmet = (props: ScreenHelmetProps) => {
  const { setScreenOptions } = usePageContext();

  useLayoutEffect(() => {
    const { preventSwipeBack, ...appBar } = props;
    setScreenOptions((prev) => ({
      ...prev,
      preventSwipeBack,
      appBar,
    }));
  }, [props, setScreenOptions]);

  return null;
};

export default ScreenHelmet;
