import { Portal } from '@daangn/karrot-clothes';
import { BoxButton } from '@daangn/sprout-components-button';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { AnimatePresence, motion } from 'framer-motion';
import { rem } from 'polished';
import * as React from 'react';

import { safeAreaInset } from '@/styles/mixins';

export type Props = {
  active: boolean;
  children?: React.ReactNode;
  onClickClose?: () => void;
  ref?: React.Ref<HTMLDivElement>;
  showCTA?: boolean;
  title?: React.ReactNode;
  topMargin?: number;
  zIndex?: number;
};

const DEFAULT_Z_INDEX = 120;
const cubicBezier = [0.23, 1, 0.32, 1];

function RenewedBottomSheet({
  ref: forwardedRef,
  active,
  zIndex = DEFAULT_Z_INDEX,
  topMargin = 80,
  showCTA = true,
  onClickClose,
  children,
}: Props) {
  return (
    <Portal selector="#root">
      <AnimatePresence>
        {active && (
          <>
            <Dim
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              onClick={onClickClose}
              transition={{ duration: 0.3, ease: cubicBezier }}
              zIndex={zIndex - 1}
            />
            <Container
              animate={{ translateY: '0%' }}
              exit={{ translateY: '100%' }}
              initial={{ translateY: '100%' }}
              ref={forwardedRef}
              topMargin={topMargin}
              transition={{ duration: 0.5, ease: cubicBezier }}
              zIndex={zIndex}
            >
              <Content>{children}</Content>
              {showCTA && (
                <CallToAction>
                  <BoxButton
                    UNSAFE_style={{ width: '100%' }}
                    onClick={onClickClose}
                    size="large"
                    variant="secondary"
                  >
                    닫기
                  </BoxButton>
                </CallToAction>
              )}
            </Container>
          </>
        )}
      </AnimatePresence>
    </Portal>
  );
}

export default RenewedBottomSheet;

const Container = styled(motion.div, {
  shouldForwardProp: (propName) => propName !== 'zIndex' && propName !== 'topMargin',
})<Pick<Props, 'topMargin' | 'zIndex'>>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: ${({ zIndex }) => zIndex ?? DEFAULT_Z_INDEX};
  max-height: calc(100vh - ${({ topMargin }) => rem(topMargin ?? 0)});
  background: ${vars.$semantic.color.paperDefault};
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  padding: ${rem(10)} 0 ${rem(8)} 0;
  border-top-left-radius: ${rem(20)};
  border-top-right-radius: ${rem(20)};
  ${safeAreaInset({ paddingBottom: 0 })}
`;

const Dim = styled(motion.div, {
  shouldForwardProp: (propName) => propName !== 'zIndex',
})<{ zIndex: number }>`
  position: absolute;
  z-index: ${({ zIndex }) => zIndex};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-color: ${vars.$semantic.color.overlayDim};
`;

const Content = styled.div`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  height: auto;
  width: 100%;
`;

const CallToAction = styled.div`
  width: 100%;
  padding: ${rem(12)} ${rem(16)} ${rem(8)};
`;
