import { useActivity } from '@stackflow/react';
import { useAtomValue } from 'jotai';
import { useEffect, useRef } from 'react';

import useLatest from '@/hooks/useLatest';
import { carTracker } from '@/sdk/tracker/CarTracker';
import { type PvEvent } from '@/sdk/tracker/PvEvent';
import { type ActivityName } from '@/stackflow';
import { assignedGroupMapState } from '@/store/experiment';

import usePrevActivity from './usePrevActivity';

export const usePvEvent = <TActivityName extends ActivityName>(
  name: TActivityName,
  params: Extract<PvEvent, { name: `${TActivityName}_pageview` }>['params']
) => {
  const flag = useRef(true);
  const activity = useActivity();
  const prevActivity = usePrevActivity();
  const experimentGroupMap = useAtomValue(assignedGroupMapState);
  const trackingParams = useLatest({ activity, prevActivity, experimentGroupMap, name, params });
  const { isTop } = activity;

  useEffect(() => {
    const track = () => {
      const { activity, prevActivity, experimentGroupMap, name, params } = trackingParams.current;
      carTracker.trackPageView(`${name}_pageview` as PvEvent['name'], params as PvEvent['params'], {
        prevActivity,
        experimentGroupMap,
        currentActivity: activity,
      });
    };
    if (isTop && flag.current) {
      track();
      flag.current = false;
    } else {
      flag.current = true;
    }
  }, [isTop, trackingParams]);
};
