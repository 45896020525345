import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { rem } from 'polished';
import React, { useRef } from 'react';
import { graphql, useFragment } from 'react-relay';

import { ArticlePromotionSection_article$key } from '@/__generated__/ArticlePromotionSection_article.graphql';
import ArticlePromotionTimer from '@/components/Article/ArticlePromotionTimer';
import Spacer from '@/components/Base/Spacer';
import { usePromotionContext } from '@/contexts/PromotionContext';
import { useEffectOnce } from '@/hooks/useEffectOnce';
import useTrack from '@/hooks/useTrack';
import { useCamelCaseParams } from '@/utils/url';

import ArticlePromotionExplanation from './ArticlePromotionExplanation';

type Props = {
  articleRef: ArticlePromotionSection_article$key;
  folded: boolean;
};

const ArticlePromotionSection: React.FC<React.PropsWithChildren<Props>> = ({
  articleRef,
  folded,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { track } = useTrack();
  const { action } = useCamelCaseParams<{ action?: string }>();
  const { open } = usePromotionContext();

  const { referrer } = useCamelCaseParams();
  const article = useFragment(
    graphql`
      fragment ArticlePromotionSection_article on Article {
        createdAt
        promotionEndsAt
        region {
          id
          name1
          name2
        }
        ...ArticlePromotionTimer_article
      }
    `,
    articleRef
  );
  const { promotionEndsAt, region } = article;

  useEffectOnce(() => {
    if (referrer === 'promotion-chat') {
      containerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    if (action === 'promotion') {
      open('payment');
    }
  });

  if (promotionEndsAt) {
    return <ArticlePromotionTimer articleRef={article} />;
  }

  return (
    <Container folded={folded} ref={containerRef}>
      {!folded && (
        <>
          <ArticlePromotionExplanation>{`${region.name1} ${region.name2}에 최소 24시간 이상 노출돼요.`}</ArticlePromotionExplanation>
          <Spacer axis="vertical" size={12} />
          <ArticlePromotionExplanation>
            {
              <>
                <Link
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    open('suggest_promotion');
                    track('article_click_open_promotion_suggestion');
                  }}
                >
                  당근 홈화면
                </Link>
                에 광고할 수 있어요.
              </>
            }
          </ArticlePromotionExplanation>
        </>
      )}
      <Button
        css={css`
          margin-top: ${rem(folded ? 0 : 20)};
        `}
        onClick={() => {
          open('payment');
          track('article_click_open_promotion_payment');
        }}
      >
        광고하기
      </Button>
    </Container>
  );
};

const Container = styled.div<{ folded: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${rem(14)};
  margin-top: ${rem(12)};
  border-radius: ${rem(6)};
  background: ${vars.$semantic.color.paperContents};

  ${({ folded }) =>
    folded &&
    css`
      margin-top: 0;
      padding: 0;
      background: ${vars.$semantic.color.paperDefault};
    `}
`;

const Link = styled.a`
  color: inherit;
  text-decoration: underline;

  &:visited {
    color: inherit;
  }
`;

const Button = styled.button`
  width: 100%;
  background-color: ${vars.$semantic.color.primaryLow};
  color: ${vars.$semantic.color.primary};
  padding: ${rem(10)} ${rem(16)};
  border-radius: ${rem(6)};
  ${vars.$semantic.typography.label3Bold}
`;

export default ArticlePromotionSection;
