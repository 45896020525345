import type { Feature, GeoJsonProperties, MultiPolygon, Polygon } from 'geojson';

export const polygonToMultiPolygon = (
  polygon: Feature<Polygon, GeoJsonProperties>
): Feature<MultiPolygon> => {
  return {
    type: 'Feature',
    properties: polygon.properties,
    geometry: {
      type: 'MultiPolygon',
      coordinates: [polygon.geometry.coordinates],
    },
  };
};
