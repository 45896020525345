import styled from '@emotion/styled';
import * as React from 'react';

import type { RatioVariant } from '@/components/Base/AspectRatio';

import AspectRatio from '@/components/Base/AspectRatio';
import { type ImageStatus, useRetryImage } from '@/hooks/useRetryImage';
import { PropsOfHtmlTag } from '@/types/React/PropsOfHtmlTag';
import { getSrcSet } from '@/utils/image';

import Box, { Props as BoxProps } from '../Box';
import LowScaleImage from './LowScaleImage';
import { fadeIn, fullCoverImageCss } from './utils';

type Props = {
  alt?: string;
  fallback?: string;
  lazy?: boolean;
  ratio: RatioVariant;
  ref?: React.Ref<HTMLDivElement>;
  render?: (status: ImageStatus) => React.ReactNode;
  sizes?: string;
  src?: string;
  width?: number | string;
} & BoxProps &
  PropsOfHtmlTag<'div'>;

function RatioImage({
  ref: forwardedRef,
  className,
  ratio,
  src,
  fallback,
  width,
  sizes,
  lazy = true,
  children,
  render,
  ...props
}: Props) {
  const { imageKey, imageProps, imageStatus } = useRetryImage();
  const targetSrc = imageStatus === 'error' ? fallback : src ?? fallback;

  return (
    <ImageBox className={className} ref={forwardedRef} w={width ?? '100%'} {...props}>
      <AspectRatio noCover ratio={ratio}>
        <PlaceholderImage className="contents" src={src} />
        {!!targetSrc && (
          <RealImage
            className="contents"
            data-image-completed={imageStatus !== 'loading'}
            key={imageKey}
            loading={lazy ? 'lazy' : 'eager'}
            sizes={sizes ?? width?.toString() ?? '100vw'}
            src={targetSrc}
            srcSet={targetSrc ? getSrcSet(targetSrc) : undefined}
            {...imageProps}
          />
        )}
        {children}
        {render?.(imageStatus)}
      </AspectRatio>
    </ImageBox>
  );
}

export default RatioImage;

const ImageBox = styled(Box)({ position: 'relative' });

const PlaceholderImage = styled(LowScaleImage)({
  ...fullCoverImageCss,
  animation: `${fadeIn} 0.1s cubic-bezier(0, 0, 0.74, 1)`,
});

const RealImage = styled.img({
  ...fullCoverImageCss,
  opacity: 0,
  transition: 'opacity 0.2s cubic-bezier(0, 0, 0.74, 1)',
  '&[data-image-completed="true"]': {
    opacity: 1,
  },
});
