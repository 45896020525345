import { ChipFilter, ChipToggleButton } from '@daangn/sprout-components-chips';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import {
  IconConfirmationFill,
  IconFilter02Thin as IconFilterThin,
  IconRetryRegular,
} from '@seed-design/icon';
import { rem } from 'polished';
import { useCallback, useEffect } from 'react';
import { match } from 'ts-pattern';

import { getSeedColor } from '@/components/Base/Box';
import HStack from '@/components/Base/HStack';
import DistanceFilterNudgingTooltip from '@/components/Filter/DistanceFilterNudgingTooltip';
import { useFeatureFlagContext } from '@/contexts/FeatureFlagContext';
import useIsInspectableRegion from '@/hooks/useIsInspectableRegion';
import useTrack from '@/hooks/useTrack';
import useTriggerOnce from '@/hooks/useTriggerOnce';
import useUpdateFilter from '@/hooks/useUpdateFilter';
import useUser from '@/hooks/useUser';
import useWarrantyBottomSheet from '@/hooks/useWarrantyBottomSheet';
import { noScrollbar, pressed } from '@/styles/mixins';
import { getSizeProps } from '@/utils/style';

import {
  OpenedFilterState,
  SortText,
  defaultFilterState,
  useFilterContext,
  useFilterUpdaterContext,
} from './FilterContextProvider';

const FilterList = () => {
  const { region } = useUser();
  const { trackWithActivityName } = useTrack();
  const { isPending } = useFilterContext();
  const { updateSort, startTransition } = useFilterUpdaterContext();
  const updateFilter = useUpdateFilter();
  const isInspectableRegion = useIsInspectableRegion();
  const [openWarrantyBottomSheet, warrantyBottomSheetNode, isBottomSheetOpened] =
    useWarrantyBottomSheet();
  const [isWarrantyBottomSheetAlreadyOpened, setWarrantyBottomSheetOpened] = useTriggerOnce(
    'isOpenedWarrantyBottomSheet'
  );

  const {
    filter: { distance, onlyOnSale, companyIds },
    sort,
    isDistanceActive,
    isOptionsActive,
    isWarrantyActive,
    hasOptionsDiff,
    isStatusActive,
    isCompanyIdsActive,
    allowedFilterFields,
  } = useFilterContext();
  const { updateOpenedFilter: setActiveFilter, resetFilter } = useFilterUpdaterContext();
  const { isEnabledWarranty } = useFeatureFlagContext();
  const regionName = region?.name3 ?? '';

  const handleClickFilter = (nextState: OpenedFilterState) => {
    setActiveFilter(nextState);
    trackWithActivityName('click_open_filter', { openedFilter: nextState });
  };

  const handleClickReset = () => {
    startTransition(() => {
      resetFilter();
      updateSort('LATEST');
    });
    trackWithActivityName('click_reset_filter');
  };

  const regionChipLabel = match(distance ?? defaultFilterState.distance)
    .with(999, () => '전국')
    .otherwise((v) => `${regionName} ${v}km`);
  const companyChipLabel = `제조사${companyIds?.length ? ` ${companyIds.length}` : ''}`;
  const statusChipLabel = onlyOnSale ? '판매중' : '모든 매물';

  const updateWarrantyScope = useCallback(() => {
    startTransition(() => {
      updateFilter({ warrantyScope: isWarrantyActive ? null : 'WARRANTABLE' });
    });
  }, [isWarrantyActive, startTransition, updateFilter]);

  const handleChangeWarrantyScope = () => {
    if (isPending) {
      return;
    }
    trackWithActivityName('click_change_filter_warranty', {
      warrantyScope: isWarrantyActive ? null : 'WARRANTABLE',
    });
    if (!isWarrantyActive && !isWarrantyBottomSheetAlreadyOpened) {
      setWarrantyBottomSheetOpened();
      openWarrantyBottomSheet();
      return;
    }
    updateWarrantyScope();
  };

  const handleClickDistance = () => handleClickFilter('distance');
  const handleClickOptions = () => handleClickFilter('options');
  const handleClickSort = () => handleClickFilter('sort');
  const handleClickCompanyIds = () => handleClickFilter('companyIds');
  const handleClickStatus = () => handleClickFilter('status');

  useEffect(() => {
    if (isBottomSheetOpened) {
      updateWarrantyScope();
    }
  }, [isBottomSheetOpened, updateWarrantyScope]);

  return (
    <Container gap={8} pl={16}>
      {(isDistanceActive ||
        (isOptionsActive && hasOptionsDiff) ||
        isStatusActive ||
        isCompanyIdsActive ||
        isWarrantyActive ||
        sort !== 'LATEST') && (
        <ResetButton onClick={handleClickReset}>
          <IconRetryRegular {...getSizeProps(18)} color={vars.$scale.color.gray900} />
        </ResetButton>
      )}
      {isInspectableRegion && isEnabledWarranty && (
        <ChipToggleButton
          isSelected={isWarrantyActive}
          onClick={handleChangeWarrantyScope}
          prefix={<IconConfirmationFill {...getSizeProps(16)} color={getSeedColor('primary')} />}
          size="small"
        >
          보증
        </ChipToggleButton>
      )}
      <DistanceFilterNudgingTooltip>
        <ChipFilter
          label={regionChipLabel}
          onClick={handleClickDistance}
          selectedValue={isDistanceActive ? regionChipLabel : undefined}
        />
      </DistanceFilterNudgingTooltip>
      <ChipFilter
        label="필터"
        onClick={handleClickOptions}
        prefix={<IconFilterThin {...getSizeProps(16)} />}
        selectedValue={isOptionsActive ? '필터' : undefined}
      />
      {allowedFilterFields.includes('sort') && (
        <ChipFilter
          label={SortText[sort]}
          onClick={handleClickSort}
          selectedValue={sort !== 'LATEST' ? SortText[sort] : undefined}
        />
      )}
      {allowedFilterFields.includes('companyIds') && (
        <ChipFilter
          label={companyChipLabel}
          onClick={handleClickCompanyIds}
          selectedValue={isCompanyIdsActive ? companyChipLabel : undefined}
        />
      )}
      <ChipFilter
        label={statusChipLabel}
        onClick={handleClickStatus}
        selectedValue={isStatusActive ? statusChipLabel : undefined}
      />
      {warrantyBottomSheetNode}
    </Container>
  );
};

const Container = styled(HStack)`
  width: 100%;
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  background-color: ${vars.$semantic.color.paperDefault};
  ${noScrollbar}
`;

const ResetButton = styled.button`
  width: ${rem(32)};
  height: ${rem(32)};
  padding: ${rem(7)};
  border-radius: 50%;
  ${pressed('gray100', 'gray200')}
`;

export default FilterList;
